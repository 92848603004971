import React from "react";
import ReactECharts from "echarts-for-react";
import { colors } from "../../utils/style/colors";


const Sunburst = ({objective, setHighlightedAction, setSelectedAction, actions}) => {

    const actionsData = actions.map(a => {
        const quarter = a.years[2022][1];
        const leads = quarter.map(org => {
            return {
                name: org.org
            }
        });
        const potential = a.potentialPartners?.map(p => {
            return {
                name: p
            }
        });
        const name = a.action.slice(0,1);
        return {
            name: name,
            children: [
              {
                name: 'lead',
                children: leads
              },
              {
                name: '',
                children: potential
              }
            ]
        }
    })


    const chartColors = [
        '#CDEAF0',
        '#35C1D8',
        '#E4F6F8',
        '#CC3F57',
        '#013964',
        '#7F7F7F'
      ];
      const bgColor = colors.backgroundWhite;
      
      const data = [
        {
          name: objective,
          itemStyle: {
            color: chartColors[0],
            opacity: 1
          },
          label: {
            color: '#334550'
          },
          children: actionsData
        }
      ];
      
      for (let j = 0; j < data.length; ++j) {
        let level1 = data[j].children;
        for (let i = 0; i < level1.length; ++i) {
          let group = level1[i].children;
          for (let org = 0; org < group.length; ++org) {
            group[org].label = {
              color: 'transparent',
              downplay: {
                opacity: 0.5
              }
            };
            if (group[org].name === 'lead') {
              group[org].itemStyle = {
                opacity: 1,
                color: '#027D89'
              };
            }
            if (group[org].children) {
              group[org].children.forEach((organization) => {
                organization.value = 1;
                organization.itemStyle = {
                  opacity: 1,
                  color: chartColors[2]
                };
                if (group[org].name === 'lead') {
                  organization.itemStyle = {
                    opacity: 1,
                    color: chartColors[1]
                  };
                  organization.label = {
                    color: '#027D89',
                    fontWeight: 'bold'
                  };
                } else {
                  organization.label = {
                    color: '#334550',
                    opacity: 0.7
                  };
                }
              });
            }
          }
          level1[i].itemStyle = {
            color: '#00B8C7'
          };
        }
      }

const option = {
    backgroundColor: 'transparent',
    title: {
      text: 'Partners in Action',
      subtext: 'Lead organizations for each action item are bolded and blue. Potential partners are grey.',
      left: 'center',
      padding: 70,
      top: 0
    },
    legend: {
      top: 'bottom',
      left: 'center'
    },
    color: chartColors,
    series: [
      {
        type: 'sunburst',
        radius: '10%',
        center: ['57%', '53%'],
        data: data,
        sort: function (a, b) {
          if (a.depth === 1) {
            return b.getValue() - a.getValue();
          } else {
            return a.dataIndex - b.dataIndex;
          }
        },
        label: {
          rotate: 'radial',
          color: bgColor
        },
        itemStyle: {
          borderColor: bgColor,
          borderWidth: 2
        },
        levels: [
          {},
          {
            r0: 0,
            r: 30,
            label: {
              rotate: 0
            }
          },
          {
            r0: 30,
            r: 90,
            label: {
              rotate: 0
            }
          },
          {
            r0: 95,
            r: 110,
            itemStyle: {
              shadowBlur: 2,
              shadowColor: '#355777',
              color: 'transparent'
            },
            label: {
              rotate: 'tangential',
              fontSize: 10,
              color: chartColors[0]
            }
          },
          {
            r0: 110,
            r: 115,
            itemStyle: {
              shadowBlur: 80,
              shadowColor: chartColors[2]
            },
            label: {
              position: 'outside',
              textShadowBlur: 5,
              textShadowColor: '#FFFFFF'
            },
            downplay: {
              label: {
                opacity: 0.5
              }
            }
          }
        ]
      }
    ]
  };


  const onClick = (param, chart) => {
    const name = param.data.name;
    if (name.length === 1) {
        setHighlightedAction(param.data.name.slice(7))
        setSelectedAction(param.data.name.slice(7));
    } else {
        setHighlightedAction('')
        setSelectedAction('')
    }
  }
 

  return (
    <ReactECharts option={option} style={{ width: '750px', height: '800px'}} onEvents={{
        'click': onClick
       }} />
  );
}

const SunburstChart = React.memo(Sunburst);

export default SunburstChart;