import React, {useState} from 'react';
import { goals } from './data';
import { motion } from "framer-motion";
import { colors } from '../../utils/style/colors';
import PovertyCharts from './PovertyCharts';
import Button from '@mui/material/Button';
import nextIcon from '../../images/icons-next-page.png';
import leftArrowIcon from '../../images/icons-left-arrow.png';
import rightArrowIcon from '../../images/icons-right-arrow.png';
import './Story.css';
import CityComparisonChart from './CityComparisonChart';

const GoalTwoCharts = ({goal}) => {
    const goalData = goals.find(g => g.goal === goal);
    const [caption, setCaption] = useState(0)
    
    const [currentData, setCurrentData] = useState(0);
    const [currentChartType, setCurrentChartType] = useState('pie');


    const povertyChartData = [
        {
            city:"Greater Portland", 
            data:
             [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 2.7, name: '2.7%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 2.7, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 4.9, name: '4.9%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 4.9, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"Boise", 
            data:
            [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 6.8, name: '6.8%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 6.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 11.9, name: '11.9%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 11.9, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"Denver", 
            data:[
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 4.5, name: '4.5%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 4.5, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 6.6, name: '6.6%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 6.6, name: '', itemStyle: {color: colors.accentGrey , opacity: 0.7} }
                     ]
                },
            ],
        },
        {
            city:"Nashville", 
            data:[
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 5.3, name: '2.7%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 5.3, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 9.8, name: '9.8%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 9.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"Pheonix", 
            data:
            [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 4.5, name: '4.5%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 4.5, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 8.5, name: '8.5%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 8.5, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"Pittsburgh", 
            data:
            [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 1.4, name: '1.4%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 1.4, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 0.2, name: '0.2%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 0.2, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"Salt Lake City", 
            data:
            [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 5.1, name: '5.1%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 5.1, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 9.7, name: '9.7%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 9.7, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"San Diego", 
            data:
            [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 4.5, name: '4.5%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 4.5, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 2.2, name: '2.2%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 2.2, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        },
        {
            city:"Seattle", 
            data:
            [
                {
                    title: 'Growth of \n Total Jobs', data: [
                        { value: 2.6, name: '2.6%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 2.6, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Growth of New \n Business \n Establishments', data: [
                        { value: 1.3, name: '1.3%', itemStyle: {color: colors.completedGreen}},
                        { value: 100 - 1.3, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
            ],
        }
    ]

    const jobsData = povertyChartData.map(d => [d.city, d.data[0].data[0].value])
    const newBusinessData = povertyChartData.map(d => [d.city, d.data[1].data[0].value])
	

//   const pieCharts = povertyChartData.filter(d => d.city !== "Greater Portland").map(c => {
//   return (<div style={{}}>
//     <PovertyCharts data={c} current={currentData} Portland={false} type={currentChartType}/>
//     <h2 style={{fontSize:"18px", color: colors.forthcomingCharcoal}}>{c.city}</h2>
//   </div>)})

  return (
    <>
        <article className="goal-charts-container no-mobile" style={{width: '90%', textAlign: 'center', display: 'flex', alignItems: 'end', marginTop: '-50px'}}>
            <div style={{border: `2px solid ${colors.accentGrey}`, borderRadius: '10px'}}>
                <PovertyCharts data={povertyChartData[0]} current={currentData} Portland type={currentChartType}/>
                <h2 style={{fontSize:"22px", color: colors.selectedDeepBlue}}>Portland</h2>
            </div>
            <div className="no-mobile" style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: "600px", overflow: 'hidden'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px', color: '#05B8C7', textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginLeft: '10%'}}>
                    Explore data related to supporting a competitive economy:
                    <div style={{display: 'flex', height: '15vh'}}>
                    <Button style={{height: '40px',width: '250px', backgroundColor: currentData === 0 ? colors.progressTeal : colors.tableRowTeal, color: currentData === 0 ? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '10px', fontSize: '14px'}} onClick={()=>{
                        setCurrentChartType('pie')
                        setCurrentData(0)
                        }}>New Jobs</Button>
                        <Button style={{height: '40px',width: '250px', backgroundColor: currentData === 1 ? colors.progressTeal : colors.tableRowTeal, color: currentData === 1 ? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '10px', fontSize: '14px'}} onClick={()=>{
                                setCurrentChartType('pie')
                                setCurrentData(1)}}> New Businesses
                        </Button>

                    </div>
                </div>
                <div style={{textAlign: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: '50px'}}>
                    <CityComparisonChart unit={'%'} dataDimension={currentData === 0 ? 'Growth of \n Total Jobs' : 'Growth of New \n Business \n Establishments'} data={currentData === 0 ? jobsData : newBusinessData} color={colors.completedGreen}/>
                </div>
            </div>
        </article>
    </>
  );
}

export default GoalTwoCharts;