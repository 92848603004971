const styleOne="enable-background:new 0 0 594 580;";
export const viewBoxOne = "0 0 594 580"
export const viewBoxTwo = "0 0 700 585"
export const viewBoxThree = "0 0 700 599"

export const goalOneIcon = <>
	<path d="M150.5,542.5v-35h113.8c2.3,0,4.5-0.9,6.2-2.6s2.6-3.9,2.6-6.2v-35h122.5c2.3,0,4.5-0.9,6.2-2.6s2.6-3.9,2.6-6.2v-26.2h96.2
		c2.3,0,4.5-0.9,6.2-2.6s2.6-3.9,2.6-6.2V271.2h26.2c3.2,0,6.2-1.8,7.7-4.6c1.5-2.8,1.4-6.3-0.4-9l-35-52.5c-1.7-2.3-4.4-3.7-7.3-3.7
		s-5.6,1.4-7.3,3.7l-35,52.5c-1.8,2.7-2,6.1-0.4,9s4.5,4.6,7.7,4.6h26.2v140h-96.2c-2.3,0-4.5,0.9-6.2,2.6s-2.6,3.9-2.6,6.2v26.2
		h-24.3l-28-92.1c-0.6-5-2.9-9.7-6.4-13.3c-0.3-0.3-0.7-0.6-1-0.9l-27.6-20.5v-61.7l34.6,16.1c2,0.9,4.3,1.1,6.4,0.4l57.9-19l0,0
		c7.6-1.3,14-6.3,17-13.3c3.1-7,2.4-15.1-1.8-21.5c-4.2-6.4-11.3-10.3-19-10.3c-0.6,0-1.2,0.1-1.8,0.2l-53,11.3l-52.5-26.6h0
		c-0.4-0.2-0.7-0.3-1.1-0.5c-3.1-0.8-6.2-1.3-9.3-1.5c6-7.6,9.3-17,9.3-26.7c0-15.6-8.3-30.1-21.9-37.9c-13.5-7.8-30.2-7.8-43.8,0
		c-13.5,7.8-21.9,22.3-21.9,37.9c0,9.6,3.3,19,9.2,26.5c-15.2,1.4-28.5,10.5-35.3,24.2l-45.6,88.7c-2.6,5.5-3.2,11.7-1.6,17.6
		s5.2,11,10.3,14.3c5.7,3.9,12.8,5.2,19.5,3.6c6.9-1.6,12.8-6,16.3-12.1l28.7-56l2.5,60.7c0.4,7.9,4.2,15.2,10.5,19.9l-14.1,63.8
		L170.7,490h-29c-2.3,0-4.5,0.9-6.2,2.6s-2.6,3.9-2.6,6.2v35H36.8v17.5h105c2.3,0,4.5-0.9,6.2-2.6S150.5,544.8,150.5,542.5
		L150.5,542.5z M500.5,225.8l18.7,28h-37.3L500.5,225.8z M264.2,446.2c-2.3,0-4.5,0.9-6.2,2.6s-2.6,3.9-2.6,6.2v35h-23.1l18.4-59.1
		l13.6-54.4l14.6,6.2l21.2,63.5L264.2,446.2z M242.3,140c7,0,13.6,2.8,18.6,7.7s7.7,11.6,7.7,18.6s-2.8,13.6-7.7,18.6
		s-11.6,7.7-18.6,7.7c-7,0-13.6-2.8-18.6-7.7c-4.9-4.9-7.7-11.6-7.7-18.6c0-7,2.8-13.6,7.7-18.6C228.6,142.8,235.3,140,242.3,140
		L242.3,140z M209.3,230.9c-3.9-0.9-7.9,1-9.7,4.6L156,320.6v0c-2.1,3.5-6.5,4.7-10.1,2.8c-3.6-1.9-5.1-6.3-3.4-10l45.6-88.7
		c2.2-4.4,5.5-8.2,9.7-10.8c4.2-2.6,9-4,13.9-4h56.7c3.9,0,7.8,0.4,11.7,0.9l54.5,27.6h0c1.8,0.9,3.8,1.2,5.8,0.8l54.9-11.7
		c2.6,0.4,4.5,2.6,4.5,5.2c0,2.6-2,4.8-4.5,5.1c-0.6,0.1-1.1,0.2-1.6,0.4l-55.4,18.1L294.3,236c-2.7-1.3-5.9-1-8.4,0.6
		c-2.5,1.6-4,4.4-4,7.4v79.8c0,2.8,1.3,5.4,3.5,7l30.5,22.6c0.7,0.9,1.1,2,1.2,3.1c0.1,0.6,0.2,1.3,0.3,1.9l26.7,87.8h-25.6
		l-24.3-72.8c-0.8-2.4-2.6-4.3-4.9-5.3l-64.2-27.4c-3.1-1.3-5.2-4.3-5.3-7.7l-3.8-94C216,235.2,213.1,231.8,209.3,230.9L209.3,230.9z
		M215.4,423.2c0.2-0.4,0.3-0.8,0.4-1.3l13.4-60.4l18.9,8.1l-14.2,56.6L214,490h-24.5L215.4,423.2z"/>
	<path d="M431.5,96.5c-1,3.1-0.3,6.5,2,8.9l27.5,28.2l-6.5,40h0c-0.5,3.3,0.9,6.6,3.6,8.6c2.7,1.9,6.3,2.1,9.3,0.5l33.6-18.6
		l33.6,18.6c2.9,1.6,6.5,1.4,9.3-0.5c2.7-1.9,4.1-5.3,3.6-8.6l-6.5-40l27.5-28.2c2.3-2.3,3.1-5.8,2-8.9c-1-3.1-3.7-5.4-7-5.9
		l-37.7-5.8l-16.9-36.1c-1.4-3.1-4.5-5-7.9-5c-3.4,0-6.5,2-7.9,5l-16.9,36.1l-37.7,5.8C435.2,91.1,432.5,93.4,431.5,96.5L431.5,96.5z
		M483.4,101.5c2.9-0.4,5.4-2.3,6.6-4.9l11-23.4l11,23.4h0c1.2,2.6,3.7,4.5,6.6,4.9l25.5,3.9l-18.7,19.2c-1.9,2-2.8,4.8-2.4,7.5
		l4.3,26.5l-22.1-12.2c-2.6-1.5-5.8-1.5-8.5,0l-22.1,12.2l4.3-26.5c0.4-2.7-0.4-5.5-2.4-7.5l-18.7-19.2L483.4,101.5z"/>
	<path d="M492.2,8.8h17.5V35h-17.5V8.8z"/>
	<path d="M428.7,49.9l12.4-12.4l18.6,18.6l-12.4,12.4L428.7,49.9z"/>
	<path d="M546.2,56.1l18.6-18.6l12.4,12.4l-18.6,18.6L546.2,56.1z"/>
	<path d="M59.1,473.4l87.5-43.8l7.8,15.7l-87.5,43.8L59.1,473.4z"/>
	<path d="M67.8,420.9l87.5-43.8l7.8,15.7l-87.5,43.8L67.8,420.9z"/>
</>;

export const goalTwoIcon = <>
<path d="M148.8,551.2c16.6,0,32.6-5.8,45.3-16.5c12.7-10.7,21.1-25.6,23.8-41.9c2.7-16.4-0.5-33.1-9.1-47.4l43.3-33.9l-10.8-13.8
L198,431.6c-16.6-16.4-40.3-23.5-63.1-18.9c-22.9,4.6-42,20.4-50.9,41.9c-8.9,21.6-6.4,46.2,6.6,65.6
C103.6,539.6,125.4,551.3,148.8,551.2L148.8,551.2z M118.1,523.8v-7.6c0-4.6,1.9-9.1,5.1-12.4c3.3-3.3,7.7-5.1,12.4-5.1h26.2
c4.6,0,9.1,1.9,12.4,5.1c3.3,3.3,5.1,7.7,5.1,12.4v7.6c-8.9,6.5-19.6,9.9-30.6,9.9S127,530.3,118.1,523.8L118.1,523.8z M135.6,468.1
c0-5.3,3.2-10.1,8.1-12.1c4.9-2,10.6-0.9,14.3,2.8s4.9,9.4,2.8,14.3c-2,4.9-6.8,8.1-12.1,8.1C141.5,481.2,135.6,475.4,135.6,468.1
L135.6,468.1z M148.8,428.8c18.4,0,35.5,9.6,45,25.4c9.5,15.8,10,35.4,1.4,51.7c-3.1-10-10.5-18.1-20.2-22
c2.9-4.7,4.4-10.2,4.5-15.7c0-10.9-5.8-21.1-15.3-26.5c-9.5-5.5-21.1-5.5-30.6,0c-9.5,5.5-15.3,15.6-15.3,26.5
c0,5.5,1.6,11,4.5,15.7c-9.7,3.9-17.1,12-20.2,22c-8.6-16.3-8.1-35.9,1.4-51.7C113.3,438.4,130.3,428.7,148.8,428.8L148.8,428.8z"/>
<path d="M280,78.8c0,17,6.3,33.5,17.5,46.2c11.3,12.8,26.8,21,43.7,23.1v44.4h17.5v-44.4c17.9-2.3,34.2-11.4,45.6-25.4
c11.4-14,16.9-31.9,15.3-49.9c-1.5-18-9.9-34.7-23.5-46.6C382.7,14.3,365.1,8.1,347,8.8c-18,0.8-35.1,8.5-47.6,21.5
C287,43.3,280,60.7,280,78.8z M336.9,65.6c0-5.3,3.2-10.1,8.1-12.1c4.9-2,10.6-0.9,14.3,2.8s4.9,9.4,2.8,14.3
c-2,4.9-6.8,8.1-12.1,8.1C342.8,78.7,336.9,72.9,336.9,65.6L336.9,65.6z M336.9,96.2h26.2c4.6,0,9.1,1.9,12.4,5.1
c3.3,3.3,5.1,7.7,5.1,12.4v7.6c-8.9,6.5-19.6,9.9-30.6,9.9s-21.7-3.5-30.6-9.9v-7.6c0-4.6,1.9-9.1,5.1-12.4
C327.8,98.1,332.2,96.3,336.9,96.2L336.9,96.2z M350,26.2c18.4,0,35.5,9.6,45,25.4c9.5,15.8,10,35.4,1.4,51.7
c-3.1-10-10.5-18.1-20.2-22c2.9-4.7,4.4-10.2,4.5-15.7c0-10.9-5.8-21.1-15.3-26.5c-9.5-5.5-21.1-5.5-30.6,0
c-9.5,5.5-15.3,15.6-15.3,26.5c0,5.5,1.6,11,4.5,15.7c-9.7,3.9-17.1,12-20.2,22c-8.6-16.3-8.1-35.9,1.4-51.7
C314.5,35.9,331.6,26.2,350,26.2L350,26.2z"/>
<path d="M551.2,411.2c-18.5,0-36.2,7.3-49.3,20.3l-43.2-33.9l-10.8,13.8l43.3,33.9c-10.6,17.8-12.8,39.4-5.9,59
c6.9,19.6,22.1,35.1,41.5,42.3c19.4,7.3,41.1,5.5,59.1-4.7c18-10.2,30.6-28,34.2-48.4c3.7-20.4-1.9-41.4-15.2-57.3
C591.6,420.4,572,411.2,551.2,411.2L551.2,411.2z M520.6,523.8v-7.6c0-4.6,1.9-9.1,5.1-12.4c3.3-3.3,7.7-5.1,12.4-5.1h26.2
c4.6,0,9.1,1.9,12.4,5.1c3.3,3.3,5.1,7.7,5.1,12.4v7.6c-8.9,6.5-19.6,9.9-30.6,9.9S529.5,530.3,520.6,523.8L520.6,523.8z
M538.1,468.1c0-5.3,3.2-10.1,8.1-12.1c4.9-2,10.6-0.9,14.3,2.8s4.9,9.4,2.8,14.3c-2,4.9-6.8,8.1-12.1,8.1
C544,481.2,538.1,475.4,538.1,468.1L538.1,468.1z M597.6,505.8c-3.1-10-10.5-18.1-20.2-22c2.9-4.7,4.4-10.2,4.5-15.7
c0-10.9-5.8-21.1-15.3-26.5c-9.5-5.5-21.1-5.5-30.6,0c-9.5,5.5-15.3,15.6-15.3,26.5c0,5.5,1.6,11,4.5,15.7c-9.7,3.9-17.1,12-20.2,22
c-8.6-16.3-8.1-35.9,1.4-51.7s26.6-25.5,45-25.5s35.5,9.7,45,25.5C605.7,469.9,606.3,489.5,597.6,505.8L597.6,505.8z"/>
<path d="M456.3,296.1l-50.5,37.8L368.9,309l6.8-5.3l-10.9-13.7l-20.5,16.3h0c-0.4,0.4-1,0.5-1.6,0.5c-0.6-0.1-1.2-0.5-1.6-1
c-1-1.4-0.8-3.2,0.3-4.5l16.4-16.1l33.4-7.3v0c1.2-0.3,2.4-0.8,3.4-1.6l30.9-23.2l-10.5-14l-29.4,22l-30.6,6.7
c-2.6-0.3-5.2,0.6-7.1,2.5l-4.6,4.6l-17.8-10v0c-2.2-1.2-4.8-1.5-7.2-0.6l-17.9,6.3l-18.4-13L272,271.7l22.1,15.6h0
c2.3,1.6,5.3,2,7.9,1.1l18.4-6.5l9.9,5.6l-1.2,1.1c-3.5,3.5-5.7,8.2-6.1,13.1c-0.4,4.9,0.9,9.9,3.8,14c3.7,5.3,9.7,8.4,16.1,8.4
c4.1,0,8.1-1.4,11.5-3.8l47.3,32c2.6,2.1,3.4,5.8,2,8.8L380.1,393c-2,2.7-4.9,4.6-8.2,5.4c-3.3,0.8-6.7,0.4-9.7-1.1
c3.1-5,4.4-11,3.5-16.8c-0.8-5.9-3.7-11.2-8.2-15.1l-0.3-0.3l-38.7-29.9c-3.8-3.3-8.6-5.3-13.6-5.6c-5-0.3-10,1.1-14.2,4l-39.9-33.2
l-11.2,13.4l40.8,34l0,0c-2.2,6.3-2.4,13.1-0.7,19.6c1.7,6.5,5.4,12.2,10.4,16.6l27,19.9v0c5.4,4.7,12.4,7.3,19.6,7.3
c3.8,0,7.6-0.8,11.1-2.3l2.9,1.9c6.8,4.6,15.1,6.3,23.2,4.9c8.1-1.4,15.3-5.8,20.2-12.4l24.2-32.5v0c0.3-0.3,0.5-0.7,0.7-1.1
c4-7.5,3.9-16.5-0.2-23.9l48-35.9L456.3,296.1z M338.1,393.7c-3.7,0.3-7.3-0.9-10-3.4l-27-19.9v0c-2.9-2.6-4.7-6.2-5.1-10.1
c-0.4-3.9,0.7-7.8,3.1-10.8c0.9-1.3,2.4-2.1,4-2.3c0.2,0,0.4,0,0.5,0c1.5,0,2.9,0.6,3.9,1.7l38.8,30c2.7,2.6,3,6.8,0.8,9.8
C345,391.5,341.7,393.3,338.1,393.7L338.1,393.7z"/>
<path d="M210,323.8c0,24.5,6.4,48.6,18.7,69.9l15.2-8.8h0c-20.4-35.4-21.8-78.6-3.7-115.2c18-36.6,53.1-61.9,93.6-67.3l-2.3-17.3h0
c-33.6,4.6-64.3,21.2-86.7,46.7C222.4,257.2,210.1,289.9,210,323.8L210,323.8z"/>
<path d="M350,446.2c-30.9,0-60.7-11.6-83.3-32.7l-11.9,12.8h0c25.9,24,59.9,37.3,95.2,37.3c35.3,0,69.3-13.3,95.2-37.3l-11.9-12.8h0
C410.7,434.6,380.9,446.3,350,446.2L350,446.2z"/>
<path d="M490,323.8c-0.1-33.9-12.4-66.6-34.7-92.1c-22.3-25.5-53.1-42.1-86.7-46.7l-2.3,17.3h0c39.7,5.4,74.2,29.7,92.5,65.3
s18.1,77.9-0.5,113.3l15.5,8.2v0C484.5,368.9,490,346.5,490,323.8L490,323.8z"/>
</>;

export const goalThreeIcon = <>
<path d="M420,245.6c0-4.6-1.9-9.1-5.1-12.4c-3.3-3.3-7.7-5.1-12.4-5.1h-5.2l-41.2-40.6c-3.4-3.4-8.9-3.4-12.3,0l-41.2,40.6h-5.2
c-4.6,0-9.1,1.9-12.4,5.1c-3.3,3.3-5.1,7.7-5.1,12.4V308l-71.9,16c-4,0.9-6.9,4.4-6.9,8.5v111.4c-44.8,23.5-83.2,57.4-112.2,98.9
l14.4,10l0,0c6.3-9,13.2-17.7,20.5-26l39.3,20h0c2.9,1.5,6.5,1.2,9.2-0.8l35.9-26.4l12.3,16v0c2.8,3.6,8,4.5,11.8,1.9l24-16h30l0,0
c2.9,0,5.6-1.4,7.2-3.8c1.6-2.4,2-5.5,0.9-8.2L280,473.3l21.3-10.4c2.3-1.1,4-3.2,4.6-5.7l7.5-29.8c32.6-3.9,65.5-2.2,97.6,4.9
l-29.9,15l0,0c-1.4,0.7-2.5,1.7-3.4,3l-17.5,26.2c-2.5,3.7-1.8,8.7,1.6,11.5l41.4,35c1.8,1.5,4.1,2.2,6.4,2l40.5-3.2l32.4,24.3h0
c1.5,1.1,3.4,1.8,5.2,1.8c0.9,0,1.9-0.2,2.8-0.4l76.5-25.5l0,0c9.9,10.8,19,22.2,27.3,34.3l14.5-9.8v0
c-28.1-41.6-65.8-75.8-109.9-99.9v-114c0-4.1-2.8-7.7-6.9-8.5L420,308L420,245.6z M350,206l22.4,22.1h-44.8L350,206z M218.8,339.5
l61.2-13.6v89.4c-21.1,4.6-41.6,11.3-61.2,20.1V339.5z M290,448.9l-25,12.2c-4.1,2-6,6.9-4.3,11.1l12.7,31.8h-19.7
c-1.7,0-3.4,0.5-4.9,1.5l-19.4,13l-12.5-16.3c-2.9-3.8-8.3-4.5-12.1-1.7l-38.5,28.3l-30.1-15.3c23.8-24,51.6-43.8,82.1-58.5h0.4
v-0.2c24.1-11.6,49.6-20,76-24.8L290,448.9z M367.5,408.4c-6.6-0.4-13.2-0.6-19.9-0.6c-5.1,0-10.1,0.2-15.1,0.5v-23.8h35V408.4z
M402.5,412.7c-5.8-1-11.6-1.9-17.5-2.6v-34.4c0-2.3-0.9-4.5-2.6-6.2c-1.6-1.6-3.9-2.6-6.2-2.6h-52.5c-2.3,0-4.5,0.9-6.2,2.6
c-1.6,1.6-2.6,3.9-2.6,6.2v33.9c-5.9,0.6-11.7,1.4-17.5,2.3V245.6h105V412.7z M553.2,507.9l-64,21.3l-31.3-23.5h0
c-1.7-1.3-3.8-1.9-5.9-1.7l-40.2,3.2l-32.6-27.6l11.9-17.8l45.7-22.9h0C480.3,452.7,520.2,476.3,553.2,507.9L553.2,507.9z
M481.2,339.5v98.1c-19.6-9.2-40.2-16.3-61.2-21.3v-90.4L481.2,339.5z"/>
<path d="M315,262.5h70V280h-70V262.5z"/>
<path d="M315,297.5h70V315h-70V297.5z"/>
<path d="M315,332.5h70V350h-70V332.5z"/>
<path d="M227.5,350h43.8v17.5h-43.8V350z"/>
<path d="M428.8,350h43.8v17.5h-43.8V350z"/>
<path d="M227.5,385h43.8v17.5h-43.8V385z"/>
<path d="M428.8,385h43.8v17.5h-43.8V385z"/>
<path d="M288.8,94.3c0,31.5,39.6,59.9,56.6,70.6v0c2.9,1.8,6.5,1.8,9.3,0c17-10.7,56.6-39.1,56.6-70.6c0.2-7.5-2.1-14.8-6.8-20.6
c-4.6-5.9-11.2-9.9-18.5-11.4c-12.8-2.9-26.2,0.5-36,9.2c-9.8-8.7-23.2-12.1-36-9.2c-7.3,1.5-13.9,5.5-18.5,11.4
C290.9,79.6,288.5,86.9,288.8,94.3L288.8,94.3z M318,79.3c1.6-0.4,3.3-0.6,5-0.6c3.9-0.1,7.8,0.9,11.1,2.8c3.4,1.9,6.2,4.8,8,8.2
c1.6,2.8,4.6,4.4,7.8,4.4s6.2-1.7,7.8-4.4c2.3-4.1,6-7.4,10.3-9.3c4.4-1.9,9.2-2.3,13.8-1.2c3.5,0.6,6.6,2.5,8.7,5.3
c2.2,2.8,3.2,6.2,3,9.7c0,17.4-22.6,38.5-43.8,52.7c-21.1-14.2-43.8-35.4-43.8-52.7c-0.2-3.5,0.9-7,3-9.7
C311.5,81.8,314.6,79.9,318,79.3L318,79.3z"/>
<path d="M428.8,87.5H455V105h-26.2V87.5z"/>
<path d="M245,87.5h26.2V105H245V87.5z"/>
<path d="M269.6,36.9l12.4-12.4l18.6,18.6l-12.4,12.4L269.6,36.9z"/>
<path d="M399.5,43.1l18.6-18.6l12.4,12.4l-18.6,18.6L399.5,43.1z"/>
<path d="M341.2,8.8h17.5V35h-17.5V8.8z"/>
<path d="M148.8,297.5c18.6,0,36.4-7.4,49.5-20.5c13.1-13.1,20.5-30.9,20.5-49.5s-7.4-36.4-20.5-49.5c-13.1-13.1-30.9-20.5-49.5-20.5
s-36.4,7.4-49.5,20.5c-13.1,13.1-20.5,30.9-20.5,49.5c0,18.6,7.4,36.4,20.5,49.5C112.4,290.1,130.2,297.5,148.8,297.5z M166.2,276.9
c-11.3,4.1-23.7,4.1-35,0v-23.2c0-4.8,3.9-8.7,8.8-8.8h17.5c4.8,0,8.7,3.9,8.8,8.8V276.9z M140,218.7c0-3.5,2.1-6.7,5.4-8.1
c3.3-1.4,7-0.6,9.5,1.9s3.2,6.3,1.9,9.5c-1.4,3.3-4.5,5.4-8.1,5.4C143.9,227.5,140,223.6,140,218.7z M148.8,175
c14.3,0,27.9,5.8,37.8,16c9.9,10.3,15.2,24.1,14.7,38.3c-0.5,14.2-6.8,27.7-17.5,37.2v-12.8c0-9-4.6-17.3-12.3-22.1
c2.3-3.9,3.5-8.3,3.5-12.9c0-9.4-5-18-13.1-22.7c-8.1-4.7-18.1-4.7-26.2,0c-8.1,4.7-13.1,13.4-13.1,22.7c0,4.5,1.2,9,3.5,12.9
c-7.6,4.8-12.2,13.1-12.3,22.1v12.8c-10.6-9.5-16.9-22.9-17.5-37.2c-0.5-14.2,4.8-28.1,14.7-38.3S134.5,175,148.8,175L148.8,175z"/>
<path d="M531.7,297.5c0.3,0,0.6,0,0.9,0c2.3-0.2,56.8-6,78.6-40.6c22-34.9,1.2-83.3,0.3-85.4c-1.5-3.5-5.1-5.6-8.9-5.2
c-2.3,0.2-56.8,6-78.6,40.6c-22,34.9-1.2,83.3-0.3,85.4h0C525.1,295.4,528.2,297.5,531.7,297.5L531.7,297.5z M596.4,247.5
c-10.4,16.4-31.6,24.8-47.2,28.9l0,0c9.2-18.2,19.5-35.8,30.9-52.8c2.4-3.5,12.3-17.7,20.4-29.1C604.2,209.2,606.8,231,596.4,247.5
L596.4,247.5z M538.8,216.3c9.9-15.7,29.7-24,45-28.3c-7.6,10.7-15.9,22.5-18.2,25.9c-11.5,17.2-22,35.1-31.4,53.6
C530.8,252.8,528.8,232.1,538.8,216.3L538.8,216.3z"/>
</>;
