import { colors } from "../../utils/style/colors";
export const barOptionRace = (data, current, Portland) => {
    return {
        colors: Object.values(colors),
        title: {
            show: false,
        },
            xAxis: {
        max: 'dataMax',
        },
        yAxis: {
        zlevel: 99,
        show: Portland,
        type: 'category',
        axisLabel: {
            inside: true,
            overflow: 'break',
            width: 300,
            show: true,
            rotate: 0,
            fontSize: 10,
            hideOverlap: false,
            color: 'black'
        },
        data: [
            'White', 
            'Black or African American', 
            'American Indian and Alaska Native', 
            'Asian', 
            'Native Hawaiian and Other Pacific Islander', 
            'Some other race', 
            'Two or more races', 
            'Hispanic or Latino origin (of any race)', 
            'White alone', 
            'not Hispanic or Latino'
        ],
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
        max: 8
        },
        series: [
        {
            colors: Object.values(colors),
            realtimeSort: true,
            colorBy: 'series',
            name: 'X',
            type: 'bar',
            data: data.data[current].data,
            label: {
            margin: 10,
            show: false,
            position: 'right',
            valueAnimation: true
            }
        }
        ],
        legend: {
        show: false
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
    }
  };
