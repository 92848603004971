import React, {useState} from 'react';
import { goals } from './data';
import { motion } from "framer-motion";
import { colors } from '../../utils/style/colors';
import PovertyCharts from './PovertyCharts';
import Button from '@mui/material/Button';
import nextIcon from '../../images/icons-next-page.png';
import leftArrowIcon from '../../images/icons-left-arrow.png';
import rightArrowIcon from '../../images/icons-right-arrow.png';
import CityComparisonChart from './CityComparisonChart';
import './Story.css';

const GoalThreeCharts = ({goal}) => {
    const goalData = goals.find(g => g.goal === goal);
    const [caption, setCaption] = useState(0)
    
    const [currentData, setCurrentData] = useState(0);
    const [currentChartType, setCurrentChartType] = useState('pie');


    const povertyChartData = [
        {
            city:"Greater Portland", 
            data:
             [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 12.5, name: '12.5%', itemStyle: {color: "#06BAC8"}},
                        { value: 87.5, name: '', itemStyle: {color: colors.accentGrey } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 0, name: '', itemStyle: {color: colors.accentGrey}},
                        { value: 365, name: '365 days of fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ],
        },
        {
            city:"Boise", 
            data:
            [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 7.5, name: '7.5%', itemStyle: {color: "#06BAC8"}},
                        { value: 92.5, name: '', itemStyle: {color: colors.accentGrey } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 0, name: '0 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 365, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"Denver", 
            data:[
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 12.3, name: '12.3%', itemStyle: {color: "#06BAC8"}},
                        { value: 87.7, name: '', itemStyle: {color: colors.accentGrey } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 34, name: '34 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 331, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"Nashville", 
            data:[
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 10.8, name: '10.8%', itemStyle: {color: "#06BAC8"}},
                        { value: 89.2, name: '', itemStyle: {color: colors.accentGrey } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 4, name: '4 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 361, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"Pheonix", 
            data:
            [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 8.5, name: '8.5%', itemStyle: {color: "#06BAC8", opacity: 0.7}},
                        { value: 91.5, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 63, name: '63 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 302, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"Pittsburgh", 
            data:
            [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 13.4, name: '13.4%', itemStyle: {color: "#06BAC8", opacity: 0.7}},
                        { value: 86.6, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 4, name: '4 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 361, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"Salt Lake City", 
            data:
            [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 4.1, name: '4.1%', itemStyle: {color: "#06BAC8", opacity: 0.7}},
                        { value: 95.9, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 20, name: '20 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 345, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"San Diego", 
            data:
            [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 12.2, name: '12.2%', itemStyle: {color: "#06BAC8", opacity: 0.7}},
                        { value: 87.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 6, name: '6 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 359, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        },
        {
            city:"Seattle", 
            data:
            [
                {
                    title: 'Foreign Born \n Residents', data: [
                        { value: 20, name: '20%', itemStyle: {color: "#06BAC8", opacity: 0.7}},
                        { value: 80, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Air Quality', data: [
                        { value: 7, name: '7 days of poor quality air per year', itemStyle: {color: colors.accentGrey}},
                        { value: 358, name: 'fresh air', itemStyle: {color: "#06BAC8" } }
                     ]
                },
            ]
        }
    ]

    const foreignResidentData = povertyChartData.map(d => [d.city, d.data[0].data[0].value])
    const airData = povertyChartData.map(d => [d.city, d.data[1].data[1].value])
	

  const pieCharts = povertyChartData.filter(d => d.city !== "Greater Portland").map(c => {
  return (<div style={{}}>
    <PovertyCharts data={c} current={currentData} Portland={false} type={currentChartType}/>
    <h2 style={{fontSize:"18px", color: colors.forthcomingCharcoal}}>{c.city}</h2>
  </div>)})

  return (
    <>
        <article className="goal-charts-container no-mobile" style={{width: '90%', textAlign: 'center', display: 'flex', alignItems: 'end', marginTop: '-50px'}}>
            <div style={{border: `2px solid ${"#06BAC8"}`, borderRadius: '10px'}}>
                <PovertyCharts data={povertyChartData[0]} current={currentData} Portland type={currentChartType}/>
                <h2 style={{fontSize:"22px", color: colors.selectedDeepBlue}}>Portland</h2>
            </div>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: "600px", overflow: 'hidden'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px', color: '#05B8C7', textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginLeft: '10%'}}>
                    Explore data related to our resilient region:
                    <div style={{display: 'flex', height: '15vh'}}>
                    <Button style={{height: '40px',width: '250px', backgroundColor: currentData === 0 ? colors.progressTeal : colors.tableRowTeal, color: currentData === 0 ? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '10px', fontSize: '14px'}} onClick={()=>{
                        setCurrentChartType('pie')
                        setCurrentData(0)
                        }}>Foreign Born Residents</Button>
                        <Button style={{height: '40px',width: '250px', backgroundColor: currentData === 1 ? colors.progressTeal : colors.tableRowTeal, color: currentData === 1 ? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '10px', fontSize: '14px'}} onClick={()=>{
                                setCurrentChartType('pie')
                                setCurrentData(1)}}> Air Quality
                        </Button>

                    </div>
                </div>
                <div style={{textAlign: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: '50px'}}>
                    <CityComparisonChart unit={currentData === 0 ? '%' : ' days'} dataDimension={currentData === 0 ? 'Foreign Born Residents' : 'Air Quality'} data={currentData === 0 ? foreignResidentData : airData} color={"#06BAC8"}/>
                </div>
            </div>
        </article>
    </>
  );
}

export default GoalThreeCharts;