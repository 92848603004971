import gpiColorLogo from '../../images/gpi-color-logo.png';
import React from 'react';

const Header = () => {
  return (
    <div className="site-header" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0px', height: '55px'}}>
      <a href="https://www.greaterportlandinc.com/" alt="return to GPI homepage">
        <img src={gpiColorLogo} style={{height: '70px', marginRight: '50px', cursor: 'pointer'}}/>
      </a>
    </div>
  );
}

export default Header;
