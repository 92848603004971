import React, {useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import { colors } from '../../utils/style/colors';

const TableFilterMenu = ({
    setFilterOpen, 
    filterOpen, 
    setOrgFilter, 
    orgFilter,
    goalFilter,
    progressFilter,
    setGoalFilter,
    setProgressFilter,
    objectiveFilter,
    setObjectiveFilter,
    orgs,
    objectives,
    progress
}) => {

    const goals = ['1', '2', '3'];


    const handleOrgFilter = (event) => {
        const {
            target: { value },
          } = event;
        setOrgFilter(typeof value === 'string' ? value.split(',') : value);
    }

    const handleGoalFilter = (event) => {
        const {
            target: { value },
          } = event;
        setGoalFilter(typeof value === 'string' ? value.split(',') : value);
    }

    const handleObjectiveFilter = (event) => {
        const {
            target: { value },
          } = event;
        setObjectiveFilter(typeof value === 'string' ? value.split(',') : value);
    }

    const handleProgressFilter = (event) => {
        const {
            target: { value },
          } = event;
        setProgressFilter(typeof value === 'string' ? value.split(',') : value);
    } 

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };

  return (
    <Drawer
      anchor='bottom'
      open={filterOpen}
      onClose={()=>setFilterOpen(false)}
    >
        <section style={{display: 'flex', padding: '20px'}}>
            <div>
                <h4>Filter by Progress Goal and Objective</h4>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="goal-filter">goal</InputLabel>
                    <Select
                    labelId="goal-filter"
                    id="goal-filter"
                    multiple
                    value={goalFilter}
                    onChange={handleGoalFilter}
                    input={<OutlinedInput id="goal-filter" label="goal" />}
                    >
                    {goals.map((g) => (
                        <MenuItem
                            key={g}
                            value={g}
                            >
                            {`Goal ${g}`}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                
                    <FormControl  disabled={goalFilter.length <= 0} sx={{ m: 1, width: 300 }}>
                        <InputLabel id="objective-filter">objective</InputLabel>
                        <Select
                        labelId="objective-filter"
                        id="objective-filter"
                        multiple
                        value={objectiveFilter}
                        onChange={handleObjectiveFilter}
                        input={<OutlinedInput id="objective-filter" label="objective" />}
                        >
                        {objectives.map((o) => (
                            <MenuItem
                                key={`${o.objective}`}
                                value={`${o.objective}`}
                                >
                                {`Objective ${o.objective}`}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl> 
            </div>
           <div>
                <h4>Filter by Progress Status</h4>
                <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="progress-filter">status</InputLabel>
                        <Select
                        labelId="progress-filter"
                        id="progress-filter"
                        multiple
                        value={progressFilter}
                        onChange={handleProgressFilter}
                        input={<OutlinedInput id="progress-filter" label="progress" />}
                        >
                        {progress.map((p) => (
                            <MenuItem
                                key={p.num}
                                value={p.num}
                                >
                                {p.label}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
            </div> 
            <div>
                <h4>Filter by Lead Organization</h4>
                <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="org-filter">lead organizations</InputLabel>
                        <Select
                        labelId="org-filter"
                        id="org-filter"
                        multiple
                        value={orgFilter}
                        onChange={handleOrgFilter}
                        input={<OutlinedInput id="org-filter" label="lead organization" />}
                        >
                        {orgs.map((org) => (
                            <MenuItem
                                key={org}
                                value={org}
                                >
                                {org}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
            </div>
        </section>
    </Drawer>
  );
}

export default TableFilterMenu;
