import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import { colors } from '../../utils/style/colors';
import './StoryHighlight.css';
import { motion, useTransform, useScroll } from "framer-motion";
import storyIcon from '../../images/icon_story.svg';

const StoryHighlight = ({story}) => {

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["end end", "start start"]
    });

    const opacity = useTransform(
      scrollYProgress,
      [0, 0.22],
      [0.5, 1]
    )

    const scaleImg = useTransform(
        scrollYProgress,
        [0.1, 0.25],
        [0.5, 1]
      )

  return (
    <motion.article
        ref={ref} 
        style={{opacity: opacity}}
        className="story-highlight-container">
        <section className="story-highlight-icon-container">
            <div className='mobile-shrink' style={{borderRadius: '50%', backgroundColor: colors.headerBlue, width: '80px', height: '80px', display: 'flex', justifyContent: 'center'}}><img style={{width: "70px"}} src={storyIcon}/></div>
        </section>
        <section className="story-highlight-content-container">
            <h2 className='mobile-font-shrink story-highlight-headline'>{story.headline}</h2>
            <p className='story-highlight-content'>{story.content}</p>
            <Button href={story.link} target='_blank' style={{backgroundColor: '#0166A6'}} variant="contained">Read More</Button>
        </section>
        {story.thumbnail && <section className='story-highlight-image-container'>
            <motion.img style={{opacity: scaleImg}} className='story-highlight-image' src={story.thumbnail} />
            <p className='mobile-font-shrink story-highlight-image-caption'>{story.caption}</p>
        </section>}
    </motion.article>
  );
}

export default StoryHighlight;