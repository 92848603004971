import Papa from 'papaparse';

function convertToThumbnail(url) {
    // Extract the file ID from the Google Drive shareable URL
    const fileIdMatch = url.match(/\/d\/(.+?)\//);
    if (fileIdMatch && fileIdMatch[1]) {
      const fileId = fileIdMatch[1];
      // Construct the thumbnail URL using the file ID
      const thumbnailUrl = `https://drive.google.com/thumbnail?id=${fileId}`;
      return thumbnailUrl;
    } else {
      console.log('Invalid Google Drive URL');
      return url;
    }
  }

export const transformProgressData = (data) => {
    const rawData = data;
    let transformedData = [];

    let latestAction = {
        goal: '',
        objective: '',
        action: '',
        outcome: '',
        metric: '',
        potentialPartners: []
    };
    let currentItem = {
        years: {
        }
    }

    rawData.forEach((item) => {
        if (item.action) {
            if(latestAction.goal){
                transformedData.push({...latestAction, ...currentItem})
                currentItem = {
                    years: {
                    }
                };
                latestAction = {
                    goal: '',
                    objective: '',
                    action: '',
                    outcome: '',
                    metric: '',
                    potentialPartners: []
                };
            }
            //set metadata
            latestAction.action = item.action
            latestAction.goal = item.goal
            latestAction.objective = item.objective
            latestAction.outcome = item.outcome
            latestAction.metric = item.metric
            
            //set potential partner
            for (let i = 1; i <= 30; i++) {
                if (item[`potentialPartners__00${i}`]) {
                    latestAction.potentialPartners.push(item[`potentialPartners__00${i}`])
                }
            }
        }
        if (item.years) {
            if (!currentItem.years[item.years]) {
                currentItem.years[item.years] = {}
            }
            if (!currentItem.years[item.years][item.quarters]) {
                currentItem.years[item.years][item.quarters] = []
            }
            currentItem.years[item.years][item.quarters].push({ org: item['quarters__||__org'], status: item['quarters__||__status'] })
        } else {
            const currentYears = Object.keys(currentItem.years)
            const latestYear = currentYears[currentYears.length - 1]
            if (item.quarters){
                if (!currentItem.years[latestYear][item.quarters]) {
                    currentItem.years[latestYear][item.quarters] = []
                } 
                currentItem.years[latestYear][item.quarters].push({ org: item['quarters__||__org'], status: item['quarters__||__status'] })
            } else {
                const latestYearQuarters = Object.keys(currentItem.years[latestYear]);
                const latestQuarter = latestYearQuarters[latestYearQuarters.length - 1];
                currentItem.years[latestYear][latestQuarter].push({ org: item['quarters__||__org'], status: item['quarters__||__status'] })
            }
        }

    })

    return transformedData;

 }
  
export const getProgressData = async () => {
    const response = await fetch('https://ceds-proxy.onrender.com/api/progress')
    const data = await response.json();
    console.log("got data from proxy", data);
    return data;
}

export const getStoryData = async () => {
    try {
        const response = await fetch('https://ceds-proxy.onrender.com/api/stories')
        const data = await response.json();
        console.log("got story data from proxy", data);
        data.data.forEach((story) => {
            story.thumbnail = convertToThumbnail(story.image);
        })
        return data.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getOrgContactData = async () => {
    try {
        const response = await fetch('https://ceds-proxy.onrender.com/api/contacts')
        const data = await response.json();
        console.log("got contact data from proxy", data);
        return data.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}
export const getPDXData = async () => {
    try {
        const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vTgUUrnjqKmsifBQsiyY9GlV73gXvmb6FKDaBNhv3dThhbJWFMG4zh4wIdaLdZEOOKmeXuu6QWIoXWl/pub?gid=260898304&single=true&output=csv');
        const data = await response.text();
        const raw = Papa.parse(data, {
            header: true
        });
        console.log("PDX", raw)
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getSettingsData = async () => {
    try {
        const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vTgUUrnjqKmsifBQsiyY9GlV73gXvmb6FKDaBNhv3dThhbJWFMG4zh4wIdaLdZEOOKmeXuu6QWIoXWl/pub?gid=1372540899&single=true&output=csv');
        const data = await response.text();
        const raw = Papa.parse(data, {
            header: true
        });
        console.log("Settings", raw)
    } catch (error) {
        console.error(error);
        return null;
    }
}
