
import React, {useEffect, useState} from "react";
import ReactECharts from "echarts-for-react";
import { colors } from "../../utils/style/colors";
import { barOptionRace } from "./barChartOptions";

const PieToBar = ({data, current, Portland, type}) => {

const widths = type === 'bar' ? ['600px', '300px'] : ['400px', '250px']

const option = type === 'bar' ? barOptionRace(data, current, Portland) :  {
        legend: {
          show: false
        },
        yAxis: {
            show: false,
            axisLabel: {
                overflow: 'break',
                width: '5px',
                show: false,
                rotate: true
            },

        },
        title: {
            text: Portland ? data.data[current].title : `${data.data[current].data[0].value}%`,
            top: Portland ? 'center' : 'bottom',
            left: 'center',
            width: '50%',
            show: true,
            color: Portland ? colors.selectedDeepBlue : colors.forthcomingCharcoal
        },
        series: [
          {
            name: 'SNAP',
            type: 'pie',
            radius: Portland ? ['40%', '70%'] : ['40%, 60%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: Portland ? 10 : 5,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              position: 'top',
              fontSize: Portland ? 16 : 14,
              fontWeight: 'bold'
            },
            legend: {
                show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data.data[current].data
          }
        ]
      };

    
    return (
        <ReactECharts 
        option={option} 
        style={{ width: Portland ? widths[0]: widths[1], height: Portland ? '400px' : '250px'}} 
        />
      );
    }
    
const PovertyCharts = React.memo(PieToBar);
    
export default PovertyCharts;
    