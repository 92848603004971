import React, { useState } from 'react';
import { useTable, useExpanded } from 'react-table';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import './ActionTable.css'
import { colors } from '../../utils/style/colors';
import { objectives } from '../Story/data';
import TableFilterMenu from './TableFilterMenu';
import emailIcon from '../../images/icon_email.svg'
import webIcon from '../../images/icons_internet.png'

 
const ActionTable = ({ progress, orgContacts }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [orgFilter, setOrgFilter] = useState([]);
  const [goalFilter, setGoalFilter] = useState([]);
  const [objectiveFilter, setObjectiveFilter] = useState([]);
  const [progressFilter, setProgressFilter] = useState([]);

  const orgsWithDuplicates = progress.map(a => a.years[2022][1].map(org => org.org)).flat();
  const orgsForFilter = [...new Set(orgsWithDuplicates)];
  const objectivesForFilter = objectives.filter(o => goalFilter.includes(`${o.goal}`));
  const progressForFilter = [{label: 'complete', num: 6},{label: 'in progress', num: 5},{label: 'overcome by events', num: 3}, {label: 'forthcoming', num: 2}, {label: 'missing data', num: 4}];

  const renderRowSubComponent = React.useCallback(({ row }) => {
      const subData = row.original;
      const miniProgress = subData.orgProgress.map(org => totalProgressBar(org.quarters, true))
      const miniProgressLabels = subData.orgProgress.map(org => <div style={{textAlign: 'right', verticalAlign: 'middle', lineHeight: '24px'}}>{org.org}</div>)
      return (
        <div style={{
            fontSize: '12px',
        }}>
            <Divider variant="middle" style={{backgroundColor: colors.headerBlue, opacity: '75%'}} />
            <section style={{display: 'flex'}}>
              {row.original.orgProgress.length > 1 ?
                <div style={{display: 'flex', justifyContent: 'flex-start', padding: '20px', paddingLeft: '114px'}}>
                  <div style={{width: '25vw'}}>{miniProgressLabels}</div>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>{miniProgress}</div>
                </div> : <div style={{padding: '20px', paddingLeft: '114px'}}>
                  <div style={{width: '25vw'}}></div>
                  <div style={{width: '550px'}}></div>
                </div>
              }
              {
                subData.potentialPartners?.length ? <article style={{textAlign: 'left', padding: '20px', width: '200px'}}>
                <h4 style={{margin: '2px', paddingBottom: '5px', textAlign: 'left' }}>Potential Partners</h4>
                {subData.potentialPartners.map(p => <p style={{margin: '2px', textAlign: 'left'}}>{p}</p>)}
                </article> : <article style={{padding: '20px', width: '200px'}}>
                </article>
              }
              {subData.resources?.length ? <article style={{textAlign: 'left', padding: '20px'}}>
                <h4 style={{margin: '2px', paddingBottom: '5px', textAlign: 'left' }}>Resources</h4>
                {subData.resources.map(r => <p style={{margin: '2px'}}>{r}</p>)}
              </article> : null}
            </section>
        </div>
     )},
    []
)

const progressColors = {
    na: colors.backgroundWhite, 
    forthcoming: colors.forthcomingCharcoal, 
    overcome: colors.notStartedYellow, 
    missingData: "repeating-linear-gradient(45deg, #FEFEFE, #FEFEFE 3px, #fade91 3px, #fade91 6px)",  
    inProgress: colors.progressTeal, 
    complete: colors.completedGreen
}

const progressTooltipText = (timeframe, premature) => {
return (
    {
        na: premature ? `Progress tracking did not begin until Q4 2022` : `Not a priority for ${timeframe}`,
        overcome: `${timeframe}: Overcome by Events`, 
        missingData: `This item was a priority for ${timeframe}, but no progress data was recorded.`, 
        forthcoming: `${timeframe} progress data forthcoming`, 
        inProgress: `${timeframe}: In Progress`, 
        complete: `${timeframe}: Complete`
    }
)
}

const cedsYears = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026"
]

const progressMap = {
  na: 0, 
  missingData: 4,
  forthcoming: 2,
  overcome: 3, 
  inProgress: 5, 
  complete: 6
}

function compareProgress(oldValue, newValue) {
    if (!oldValue) {
        return newValue;
    } else {
        return progressMap[newValue] > progressMap[oldValue] ? newValue : oldValue;
    }
}

function orgs(orgList){
    const withContactInfo = orgList.map(org => {
      const contact = orgContacts.find(o => o.org === org);
      let o = {org: org}
      if (contact?.emailOne) {
        o.emailOne = contact.emailOne;
      }
      if (contact?.emailTwo) {
        o.emailTwo = contact.emailTwo;
      }
      if (contact?.website) {
        o.website = contact.website;
      }
      return o;
    })
    const leads = withContactInfo.map(o => {
        return <div style={{marginBottom: '10px'}}>
                    <p style={{marginTop: '0px'}}>{o.org}</p>
                    <div style={{display: 'flex', justifyContent: 'space-evenly', width: '40px', marginTop: '-8px'}}>
                        {o.emailOne && 
                          <Tooltip placement="top-start" title={`email ${o.emailOne}`} arrow>
                            <a target='_blank' href={`mailto:${o.emailOne}`}><div style={{backgroundColor: colors.headerBlue, borderRadius: '50%', width: '25px', height: '25px', display: 'flex', justifyContent: 'center', marginLeft: '5px', marginRight: '5px'}}><img style={{width: '20px'}} src={emailIcon}/></div></a>
                          </Tooltip>
                        }
                        {o.emailTwo && 
                          <Tooltip placement="top-start" title={`email ${o.emailTwo}`} arrow>
                            <a target='_blank' href={`mailto:${o.emailTwo}`}><div style={{backgroundColor: colors.headerBlue, borderRadius: '50%', width: '25px', height: '25px', display: 'flex', justifyContent: 'center', marginLeft: '5px', marginRight: '5px'}}><img style={{width: '20px'}} src={emailIcon}/></div></a>
                          </Tooltip>
                        }
                        {o.website && 
                          <Tooltip placement="top-start" title={`partner website: ${o.website}`} arrow>
                            <a target='_blank' href={o.website}><div style={{backgroundColor: colors.headerBlue, borderRadius: '50%', width: '25px', height: '25px', display: 'flex', justifyContent: 'center', marginRight: '-5px'}}><img style={{width: '20px'}} src={webIcon}/></div></a>
                          </Tooltip>
                        }
                    </div>
                </div>
    })
    return <div className='org-column-content'>
        {leads}
    </div>  
}

function totalProgressBar(quarters, mini) {
    const yearHeight = mini ? '16px' : '30px';
    const quarterHeight = mini ? '16px' : '28px';
    const divHeight = mini ? '22px' : '32px';
    let years = [];
    quarters.flat().flat().forEach((q,i) => {
        if (i < 4) {
            years[0] = compareProgress(years[0], q);
        } else if (i < 8) {
            years[1] = compareProgress(years[1], q);
        } else if (i < 12) {
            years[2] = compareProgress(years[2], q);
        } else if (i < 16) {
            years[3] = compareProgress(years[3], q);
        } else if (i < 20) {
            years[4] = compareProgress(years[4], q);
        } 
    })
    const quartersViz = (year) => {
        const firstQuarterIndex = year*4;
        const fourQuarters = quarters.flat().slice(firstQuarterIndex, firstQuarterIndex + 4)
        return fourQuarters.map((q,i) =>  <Tooltip placement="top-start" title={progressTooltipText(`Q${i + 1} ${cedsYears[year]}`, year === 0 && i < 3)[q]} arrow>
            <div className="quarters-progress" style={{fontSize: '10px', height: quarterHeight, width: '0px', visibility: 'hidden', background: progressColors[q], border: `1px solid ${q === 'missingData' || q === 'na' ? colors.accentGrey : 'progressColors[q]'}` }}></div>
        </Tooltip>)
    }
    const yearsViz = years.map((y,i) => {
        if (y !== 'na' && y !== 'forthcoming') {
            return <div className='year-progress-square' style={{
                display: 'flex', 
                justifyContent: 'space-evenly', 
                margin: '2px', 
                height: yearHeight, 
                width: '30px', 
                background: progressColors[y],
                border: `1px solid ${y === 'missingData' ? colors.accentGrey : progressColors[y]}`
                }}>
                    {quartersViz(i)}
                 </div>
        } else {
            return <Tooltip placement="top-start" title={progressTooltipText(cedsYears[i], false)[y]} arrow>
                 <div className="empty-year-square" style={{justifyContent: 'space-evenly', margin: '2px', height: yearHeight, width: '30px', backgroundColor: progressColors[y], border: `1px solid ${y === 'missingData' || y === 'na' ? colors.accentGrey : progressColors[y]}`}}>
                 </div>
            </Tooltip>
        }
    })
    
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'start', width: '200px', height: divHeight}}>
            {yearsViz}
        </div>
    )
}


  const totalProgressCalc = (years) => {
    const summaryOfYears = Object.keys(years).map(y => {
        const oneYear = years[y];
        const status = Object.keys(oneYear).map(q => {
          let max;
          oneYear[q].forEach(org => {
            if(!max){
              max = org.status;
            } else if (compareProgress(max, org.status) === org.status){
              max = org.status;
            }
          })
          return max;
        })
        return status;
      })
    return summaryOfYears
  }

  const orgProgressCalc = (years) => {
    let orgProgress = [];
    const singleQuarter = years[2022][1];
    singleQuarter.forEach(org => {
      const summaryOfYears = Object.keys(years).map(y => {
        const oneYear = years[y];
        const status = Object.keys(oneYear).map(q => {
          const filtered = oneYear[q].filter(o => o.org === org.org);
          if(!filtered[0]) {
            console.log(singleQuarter, years)
          }
          return filtered[0].status
          })
          return status;
        })
        orgProgress.push({org: org.org, quarters: summaryOfYears})
      })
    return orgProgress;
  }


   const data = React.useMemo(
     () => progress.filter(a => {
      let conditions = true;
      if(goalFilter.length > 0){
        conditions = goalFilter.includes(a.goal);
      }
      if(objectiveFilter.length > 0){
        conditions = conditions && objectiveFilter.includes(a.objective)
      }
      if(progressFilter.length > 0){
        const numbers = totalProgressCalc(a.years).flat().map(s => progressMap[s]);
        const max = Math.max(...numbers);
        conditions = conditions && progressFilter.includes(max)
      }
      if(orgFilter.length > 0){
        let includes = false;
        a.years[2022][1].map(org => org.org).forEach(o => {
          if(orgFilter.includes(o)){
            includes = true
          }
        })
        conditions = conditions && includes
      }
      return conditions;
     }).map(a => {
      return {
        objective: <div style={{width: '30px'}}>{a.objective}</div>,
         action: <div style={{width: '25vw'}}>{a.action}</div>,
         progress: totalProgressBar(totalProgressCalc(a.years), false),
         outcome: <div className='outcome-column-content'>{a.outcome}</div>,
         metric: <div className='metric-column-content'>{a.metric}</div>,
         leadOrg: orgs(a.years[2022][1].map(org => org.org)),
         orgProgress: orgProgressCalc(a.years),
         potentialPartners: a.potentialPartners,
         resources: a.resources
      }
     }),
     [goalFilter, objectiveFilter, progressFilter, orgFilter]
   )

 
   const columns = React.useMemo(
     () => [
       {
         Header: 'Objective',
         accessor: 'objective', // accessor is the "key" in the data
       },
       {
         Header: 'Action',
         accessor: 'action',
       },
       {
        Header: <div style={{width: '200px', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                    <p style={{width: '34px'}}>22'</p><p style={{width: '34px'}}>23'</p><p style={{width: '34px'}}>24'</p><p style={{width: '34px'}}>25'</p><p style={{width: '34px'}}>26'</p>
                </div>,
        accessor: 'progress',
      },
      {
        Header: 'Priority Outcome',
        accessor: 'outcome',
      },
      {
        Header: 'Recommended Metric',
        accessor: 'metric',
      },
      {
        Header: 'Lead Organizations',
        accessor: 'leadOrg',
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? 'V' : '>'}
          </span>
        ),
      },
     ],
     []
   )
 
   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
     visibleColumns
   } = useTable({ columns, data }, useExpanded)
 
   return (
    <section className='no-mobile' style={{margin: '40px', marginTop: '20px' }}>
      <div style={{margin: '10px', marginTop: '0px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Button variant="outline" style={{marginLeft: '0px', height: '30px', width: '100px', border: `1px solid ${colors.forthcomingCharcoal}`}} onClick={() => setFilterOpen(true)}>Filter</Button>
        <section className='no-mobile' style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontSize: '14px', minWidth: '400px', marginRight: '30px'}}>
              <div style={{backgroundColor: colors.completedGreen, borderRadius: '50%', width: '20px', height: '20px', display: 'inline',margin: '0 0.125em',padding: 0,verticalAlign: 'baseline'}}></div>
              <p style={{marginRight: '1vw'}}>= completed</p>
              <div style={{backgroundColor: colors.progressTeal, borderRadius: '50%', width: '20px', height: '20px', display: 'inline',margin: '0 0.125em',padding: 0,verticalAlign: 'baseline'}}></div>
              <p style={{marginRight: '1vw'}}>= in progress</p>
              <div style={{backgroundColor: colors.notStartedYellow, borderRadius: '50%', width: '20px', height: '20px', display: 'inline',margin: '0 0.125em',padding: 0,verticalAlign: 'baseline'}}></div>
              <p style={{marginRight: '1vw'}}>= overcome by events</p>
              <div style={{backgroundColor: colors.forthcomingCharcoal, borderRadius: '50%', width: '20px', height: '20px', display: 'inline',margin: '0 0.125em',padding: 0,verticalAlign: 'baseline'}}></div>
              <p style={{marginRight: '1vw'}}>= forthcoming</p>
              <div style={{background: "repeating-linear-gradient(45deg, #FEFEFE, #FEFEFE 3px, #fade91 3px, #fade91 6px)", borderRadius: '50%', width: '20px', height: '20px', display: 'inline',margin: '0 0.125em',padding: 0,verticalAlign: 'baseline'}}></div>
              <p style={{marginRight: '1vw'}}>= missing data</p>
          </section>
      </div>
      <div style={{height: '80vh', overflow:'scroll', padding: '0px'}}>
      <TableFilterMenu 
        setFilterOpen={setFilterOpen} 
        filterOpen={filterOpen} 
        orgFilter={orgFilter}
        setOrgFilter={setOrgFilter}
        goalFilter={goalFilter}
        objectiveFilter={objectiveFilter}
        progressFilter={progressFilter}
        setGoalFilter={setGoalFilter}
        setObjectiveFilter={setObjectiveFilter}
        setProgressFilter={setProgressFilter}
        orgs={orgsForFilter}
        objectives={objectivesForFilter}
        progress={progressForFilter}
      />
     <table {...getTableProps()} style={{margin: '0px', borderSpacing: '0px'}}>
       <thead style={{position: 'sticky', top: '0px', margin: '0 0 0 0'}}>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th
                 {...column.getHeaderProps()}
                 style={{
                   background: colors.headerBlue,
                   color: colors.backgroundWhite,
                   fontSize: '14px',
                   padding: '10px'
                 }}
               >
                 {column.render('Header')}
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map((row, i) => {
           prepareRow(row)
           return (
            <>
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (
                   <td
                     {...cell.getCellProps()}
                     style={{
                       margin: '0px',
                       padding: '10px',
                       background: i % 2 === 0 ? colors.tableRowTeal : colors.backgroundWhite,
                       fontSize: '12px',
                       textAlign: 'left',
                       verticalAlign: 'top'
                     }}
                   >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
             {row.isExpanded ? (
                <tr {...row.getRowProps()}>
                  <td colSpan={visibleColumns.length}
                  style={{
                    background: i % 2 === 0 ? colors.tableRowTeal : colors.backgroundWhite,
                  }}>
                    {/*
                        Inside it, call our renderRowSubComponent function. In reality,
                        you could pass whatever you want as props to
                        a component like this, including the entire
                        table instance. But for this example, we'll just
                        pass the row
                      */}
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </>
           )
         })}
       </tbody>
     </table>
     </div>
    </section>
   )
 }

export default ActionTable;