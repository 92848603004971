import React from 'react';
import './ActionTableHeader.css';
import bikersOnBridge from '../../images/bikers-on-bridge.jpg';
import cityView from '../../images/city-view.jpeg';
import {viewBoxOne, viewBoxTwo, viewBoxThree, goalOneIcon, goalTwoIcon, goalThreeIcon} from '../../images/goalIcons.js';
import { colors } from '../../utils/style/colors';
import { motion } from "framer-motion";
// import { objectives } from '../Story/data';

const ActionTableHeader = ({setGoal, setObjective, page, goal}) => {

    // const objectivesFiltered = objectives.filter(o => o.goal === goal)
    // const objectivesDisplay = objectivesFiltered.map(o => {
    //             return (<p><strong>{o.objective + '  ' + o.description}</strong></p>)
    // })

    // console.log(objectivesDisplay)

  return (
    <div className="table-view-header" style={{width: '100%', height: '60vh', overflow: 'hidden', backgroundColor: colors.headerBlue, display: 'flex', flexDirection: 'column'}}>
      <img src={page === 'table' ? bikersOnBridge : cityView} style={{width: '100vw', height: '48vh', marginTop: '2vh', objectFit: 'cover', objectPosition: `50% ${page === 'story' ? '20%' : '70%'}`}}/>
      <div style={{display: 'flex', justifyContent: 'space-between', overflow: 'visible'}}>
        <section className='no-mobile' style={{display: 'flex', height: '200px', position: 'relative', bottom: '150px', left: '2vw', color: colors.backgroundWhite}}>
            <motion.div className="goal-circle-set" whileHover={{ scale: 1.1 }}>
                <svg style={{position: 'relative', bottom: '25px', right: '14px', rotate: "195deg"}} height="200" width="200" viewBox="0 0 20 20">
                    <circle 
                            r="5"
                            cx="10" cy="10" fill="transparent"
                            stroke={colors.objectiveOneTeal}
                            strokeWidth="10"
                            strokeDasharray="4 28"/>
                    <circle 
                        r="5" cx="10" cy="10" fill="transparent"
                        stroke={colors.objectiveTwoTeal}
                        strokeWidth="10"
                        strokeDasharray="4 28" 
                        transform="rotate(46, 10, 10)"/>
                    <text className={goal === 1 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue} x="3" y="6" transform="rotate(-195, 10, 10)">1.1</text>
                    <text className={goal === 1 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue}  x="8" y="3.5" transform="rotate(-195, 10, 10)">1.2</text>

                </svg>
                <motion.div 
                    whileHover={{ scale: 0.98 }}
                    onClick={()=>{
                        if(goal !== 1){
                            setGoal(1);
                            setObjective(1.1)
                        } else {
                            setGoal('')
                            setObjective(1.1)
                        }
                    }} className="goal-circle" style={{display: 'flex', cursor: 'pointer', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.goalNavy, borderRadius: '50%', width: '175px', height: '175px', margin: '20px', position: 'relative', bottom: '200px', left: '0'}}>
                    <motion.svg className={goal === 1 ? 'goal-icon-hidden':"goal-icon"} style={{width: '110px', fill: colors.backgroundWhite}} viewBox={viewBoxOne}>
                        {goalOneIcon}
                    </motion.svg>
                    <h3 className={goal === 1 ? 'goal-label-active' :"goal-label"}>FOSTER UPWARD MOBILITY</h3>
                    <h3 style={{color: goal === 1 ? colors.progressTeal : 'white', marginTop: '0px', fontSize: '14px'}}>GOAL 1</h3>
                </motion.div>
            </motion.div>
            <motion.div className="goal-circle-set" whileHover={{ scale: 1.1 }}>
                <svg style={{position: 'relative', bottom: '25px', right: '0px', rotate: "195deg"}} height="200" width="200" viewBox="0 0 20 20">
                    <circle r="5" cx="10" cy="10" fill="transparent"
                            stroke={colors.objectiveOneTeal}
                            strokeWidth="10"
                            strokeDasharray="4 28"/>
                    <circle r="5" cx="10" cy="10" fill="transparent"
                        stroke={colors.objectiveTwoTeal}
                        strokeWidth="10"
                        strokeDasharray="4 28" 
                        transform="rotate(46, 10, 10)"/>
                    <circle r="5" cx="10" cy="10" fill="transparent"
                        stroke={colors.objectiveThreeTeal}
                        strokeWidth="10"
                        strokeDasharray="4 28" 
                        transform="rotate(92, 10, 10)"/>
                    <text className={goal === 2 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue} x="2.5" y="5.5" transform="rotate(-195, 10, 10)">2.1</text>
                    <text className={goal === 2 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue} x="7.5" y="3" transform="rotate(-195, 10, 10)">2.2</text>
                    <text className={goal === 2 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue} x="14" y="5" transform="rotate(-195, 10, 10)">2.3</text>

                </svg>
                <motion.div whileHover={{ scale: 0.98 }} onClick={()=>{
                    if(goal !== 2){
                        setGoal(2);
                        setObjective(2.1)
                    } else {
                        setGoal('')
                        setObjective(1.1)
                    }
                    }} className="goal-circle" style={{display: 'flex', cursor: 'pointer', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.goalNavy, borderRadius: '50%', width: '175px', height: '175px', margin: '20px', position: 'relative', bottom: '200px', left: '0'}}>
                <svg className={goal === 2 ? 'goal-icon-hidden':"goal-icon"} style={{width: '120px', fill: colors.backgroundWhite}} viewBox={viewBoxTwo}>
                    {goalTwoIcon}
                </svg>
                <h3 className={goal === 2 ? 'goal-label-active' :"goal-label"}>SUPPORT A COMPETITIVE ECONOMY</h3>
                <h3 style={{marginTop: '0px', fontSize: '14px', color: goal === 2 ? colors.progressTeal : 'white'}}>GOAL 2</h3>
                </motion.div>
            </motion.div>
            <motion.div className="goal-circle-set" whileHover={{ scale: 1.1 }}>
                <svg style={{position: 'relative', bottom: '25px', right: '-5px', rotate: "250deg"}} height="200" width="200" viewBox="0 0 20 20">
                    <circle r="5" cx="10" cy="10" fill="transparent"
                            stroke={colors.objectiveOneTeal}
                            strokeWidth="10"
                            strokeDasharray="4 28"
                            transform="rotate(0, 10, 10)"/>
                    <circle r="5" cx="10" cy="10" fill="transparent"
                        stroke={colors.objectiveTwoTeal}
                        strokeWidth="10"
                        strokeDasharray="4 28" 
                        transform="rotate(46, 10, 10)"/>
                    <text className={goal === 3 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue} x="9" y="3" transform="rotate(-250, 10, 10)">3.1</text>
                    <text className={goal === 3 ? 'objective-fan-text-active' :"objective-fan-text"} fill={colors.selectedDeepBlue} x="14" y="5.5" transform="rotate(-250, 10, 10)">3.2</text>

                </svg>
                    <motion.div whileHover={{ scale: 0.98 }} onClick={()=>{
                        if(goal !== 3){
                            setGoal(3);
                            setObjective(3.1)
                        } else {
                            setGoal('')
                            setObjective(1.1)
                        }
                        }} className="goal-circle"  style={{display: 'flex', cursor: 'pointer', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.goalNavy, borderRadius: '50%', width: '175px', height: '175px', margin: '20px', position: 'relative', bottom: '200px', left: '0'}}>
                    <svg className={goal === 3 ? 'goal-icon-hidden':"goal-icon"} style={{width: '120px', fill: colors.backgroundWhite}} viewBox={viewBoxThree}>
                        {goalThreeIcon}
                    </svg>
                    <h3 className={goal === 3 ? 'goal-label-active' :"goal-label"}>BUILD A RESILIENT REGION</h3>
                    <h3 style={{color: goal === 3 ? colors.progressTeal : 'white', marginTop: '0px', fontSize: '14px'}}>GOAL 3</h3>
                </motion.div>
            </motion.div>
        </section>
      </div>
    </div>
  );
}

export default ActionTableHeader;