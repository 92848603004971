import React, {useState} from 'react';
import { goals } from './data';
import { motion } from "framer-motion";
import { colors } from '../../utils/style/colors';
import PovertyCharts from './PovertyCharts';
import Button from '@mui/material/Button';
import nextIcon from '../../images/icons-next-page.png';
import leftArrowIcon from '../../images/icons-left-arrow.png';
import rightArrowIcon from '../../images/icons-right-arrow.png';
import './Story.css';
import CityComparisonChart from './CityComparisonChart';

const GoalOneCharts = ({goal}) => {
    const goalData = goals.find(g => g.goal === goal);
    const [caption, setCaption] = useState(0)
    
    const [currentData, setCurrentData] = useState(0);
    const [currentChartType, setCurrentChartType] = useState('pie');


    const povertyChartData = [
        {
            city:"Greater Portland", 
            data:
             [
                {
                    title: 'SNAP Users', data: [
                        { value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy}},
                        { value: 86.8, name: '', itemStyle: {color: colors.accentGrey } }
                     ]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 10, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                        { value: 90, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 8.6, itemStyle: {color: colors.accentGrey}},{value: 18.8, itemStyle: {color:colors.completedGreen}},{value: 12.3, itemStyle: {color:colors.objectiveOneTeal}},{value: 9.0},{value: 36.5, itemStyle: {color:colors.objectiveTwoTeal}},{value: 14.2, itemStyle:{color: colors.forthcomingCharcoal}},{value: 13.8, itemStyle: {color: colors.accentGrey}},{value: 15.3, itemStyle: {color: colors.headerBlue}},{value: 8.4, itemStyle: {color: colors.objectiveThreeTeal}}
,                    ]
                }
            ]
        },
        {
            city:"Boise", 
            data:
             [
                {
                    title: 'SNAP Users', data: [{ value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy, opacity: 0.7}},
                    { value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 9.8, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                { value: 90.2, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 8.7, itemStyle: {color: colors.accentGrey}},{value: 32.5, itemStyle: {color:colors.completedGreen}},{value: null, itemStyle: {color:colors.objectiveOneTeal}},{value: 10.0, itemStyle: {color:colors.objectiveTwoTeal}},{value: null, itemStyle:{color: colors.forthcomingCharcoal}},{value: 16.1, itemStyle: {color: colors.accentGrey}},{value: 10.9, itemStyle: {color: colors.headerBlue}},{value: 11.2, itemStyle: {color: colors.objectiveThreeTeal}},{value: 8.7, itemStyle: {color: colors.progressTeal}}
,                    ]
                }
            ]
        },
        {
            city:"Denver", 
            data:[
                {
                    title: 'SNAP Users', data: [{ value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy, opacity: 0.7}},
                    { value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 8.4, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                        { value: 91.6, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 6.2, itemStyle: {color: colors.accentGrey}},{value: 19.1, itemStyle: {color: colors.completedGreen}}, {value: 13.4, itemStyle: {color:colors.objectiveOneTeal}},{value: 7.2, itemStyle: {color:colors.objectiveTwoTeal}},{value: null, itemStyle:{color: colors.forthcomingCharcoal}},{value: 13.2, itemStyle: {color: colors.accentGrey}},{value: 11.8, itemStyle: {color: colors.headerBlue}},{value: 12.8, itemStyle: {color: colors.objectiveThreeTeal}},{value: 5.8, itemStyle: {color: colors.progressTeal}}
,                    ]
                }
            ]
        },
        {
            city:"Nashville", 
            data:[
                    {
                        title: 'SNAP Users', data: [{ value: 13.2, name: '13.2%', itemStyle:     {color: colors.goalNavy, opacity: 0.7}},{ value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }]
                    },
                    {
                        title: 'Poverty Rate', data: [
                            { value: 10.6, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                            { value: 89.4, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                         ]
                    },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 8.3, itemStyle: {color: colors.accentGrey}},{value: 17.9, itemStyle: {color:colors.completedGreen}},{value: 7.9, itemStyle: {color:colors.objectiveOneTeal}},{value: 14.3, itemStyle: {color:colors.objectiveTwoTeal}},{value: null, itemStyle:{color: colors.forthcomingCharcoal}},{value: 20.8, itemStyle: {color: colors.accentGrey}},{value: 12.8, itemStyle: {color: colors.headerBlue}},{value: 18.2, itemStyle: {color: colors.objectiveThreeTeal}},{value: 8.0, itemStyle: {color: colors.progressTeal}}
,                    ]
                }
                ]
        },
        {
            city:"Pheonix", 
            data:
             [
                {
                    title: 'SNAP Users', data: [
                        { value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy, opacity: 0.7}},
                        { value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 11.1, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                        { value: 88.9, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 8.3, itemStyle: {color: colors.accentGrey}},{value: 15.7, itemStyle: {color:colors.completedGreen}},{value: 22.0, itemStyle: {color:colors.objectiveOneTeal}},{value: 10.3, itemStyle: {color:colors.objectiveTwoTeal}},{value: 10.0, itemStyle:{color: colors.forthcomingCharcoal}},{value: 17.4, itemStyle: {color: colors.accentGrey}},{value: 13.9, itemStyle: {color: colors.headerBlue}},{value: 15.7, itemStyle: {color: colors.objectiveThreeTeal}},{value: 7.6, itemStyle: {color: colors.progressTeal}}
,                    ]
                }
            ]
        },
        {
            city:"Pittsburgh", 
            data:
             [
                {
                    title: 'SNAP Users', data: [
                        { value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy, opacity: 0.7}},
                        { value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 11.2, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                        { value: 88.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 9.4, itemStyle: {color: colors.accentGrey}},{value: 26.1, itemStyle: {color:colors.completedGreen}},{value: 27.6, itemStyle: {color:colors.objectiveOneTeal}},{value: 13.6, itemStyle: {color:colors.objectiveTwoTeal}},{value: null, itemStyle:{color: colors.forthcomingCharcoal}},{value: 21.3, itemStyle: {color: colors.accentGrey}},{value: 16.2, itemStyle: {color: colors.headerBlue}},{value: 18.0, itemStyle: {color: colors.objectiveThreeTeal}},{value: 9.3, itemStyle: {color: colors.progressTeal}}
,                    ]
                }
            ]
        },
        {
            city:"Salt Lake City", 
            data:
             [
                {
                    title: 'SNAP Users', data: [
                        { value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy, opacity: 0.7}},
                        { value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 8.2, name: '8.2%', itemStyle: {color: colors.goalNavy}},
                        { value: 91.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 8.6, itemStyle: {color: colors.accentGrey}},{value: 18.8, itemStyle: {color:colors.completedGreen}},{value: 12.3, itemStyle: {color:colors.objectiveOneTeal}},{value: 9.0, itemStyle: {color:colors.objectiveTwoTeal}},{value: 36.5, itemStyle:{color: colors.forthcomingCharcoal}},{value: 14.2, itemStyle: {color: colors.accentGrey}},{value: 13.8, itemStyle: {color: colors.headerBlue}},{value: 15.3, itemStyle: {color: colors.objectiveThreeTeal}},{value: 8.4, itemStyle: {color: colors.progressTeal}}
,                    ]
                }
            ]
        },
        {
            city:"San Diego", 
            data:
             [
                {
                    title: 'SNAP Users', data: [
                        { value: 13.2, name: '13.2%', itemStyle: {color: colors.goalNavy, opacity: 0.7}},
                        { value: 86.8, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 10.6, name: '10.6%', itemStyle: {color: colors.goalNavy}},
                        { value: 91.4, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 7.3, itemStyle: {color: colors.accentGrey}},{value: 29.7, itemStyle: {color:colors.completedGreen}},{value: 14.6, itemStyle: {color:colors.objectiveOneTeal}},{value: 5.5, itemStyle: {color:colors.objectiveTwoTeal}},{value: null, itemStyle:{color: colors.forthcomingCharcoal}},{value: 12.1, itemStyle: {color: colors.accentGrey}},{value: 9.4, itemStyle: {color: colors.headerBlue}},{value: 11.6, itemStyle: {color: colors.objectiveThreeTeal}},{value: 7.0, itemStyle: {color: colors.progressTeal}},                    ]
                }
            ]
        },
        {
            city:"Seattle", 
            data:
             [
                {
                    title: 'SNAP Users', data: [
                        { value: 9.5, name: '9.5%', itemStyle: {color: colors.goalNavy}},
                        { value: 91.4, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate', data: [
                        { value: 8.6, name: '8.6%', itemStyle: {color: colors.goalNavy}},
                        { value: 91.4, name: '', itemStyle: {color: colors.accentGrey, opacity: 0.7 } }
                     ]
                },
                {
                    title: 'Poverty Rate By Race', data: [
                        {value: 7.0, itemStyle: {color: colors.accentGrey}},{value: 20.3, itemStyle: {color:colors.completedGreen}},{value: 6.6, itemStyle: {color:colors.objectiveOneTeal}},{value: 7.7, itemStyle: {color:colors.objectiveTwoTeal}},{value: 13.5, itemStyle:{color: colors.forthcomingCharcoal}},{value: 15.9, itemStyle: {color: colors.accentGrey}},{value: 8.8, itemStyle: {color: colors.objectiveThreeTeal}},{value: 12.2, itemStyle: {color: colors.progressTeal}},{value: 6.9}
                    ]

                }
            ]
        }
    ]

    const snapData = povertyChartData.map(d => [d.city, d.data[0].data[0].value])
    const povertyData = povertyChartData.map(d => [d.city, d.data[1].data[0].value])
	

  const pieCharts = povertyChartData.filter(d => d.city !== "Greater Portland").map(c => {
  return (<div style={{}}>
    <PovertyCharts data={c} current={currentData} Portland={false} type={currentChartType}/>
    <h2 style={{fontSize:"18px", color: colors.forthcomingCharcoal}}>{c.city}</h2>
  </div>)})

  return (
    <>
        <article className="goal-charts-container no-mobile" style={{width: '90%', textAlign: 'center', display: 'flex', alignItems: 'end', marginTop: '-50px'}}>
            <div style={{border: `2px solid ${colors.accentGrey}`, borderRadius: '10px'}}>
                <PovertyCharts data={povertyChartData[0]} current={currentData} Portland type={currentChartType}/>
                <h2 style={{fontSize:"22px", color: colors.selectedDeepBlue}}>Portland</h2>
            </div>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: "600px", overflow: 'hidden'}}>
                <div style={{fontWeight: 'bold', fontSize: '20px', color: '#05B8C7', textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginLeft: '10%'}}>
                    Explore data related to economic mobility in Greater Portland:
                    <div style={{display: 'flex', height: '15vh'}}>
                    <Button style={{height: '40px',width: '175px', backgroundColor: currentData === 0 ? colors.progressTeal : colors.tableRowTeal, color: currentData === 0 ? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '10px', fontSize: '16px'}} onClick={()=>{
                        setCurrentChartType('pie')
                        setCurrentData(0)
                        }}>SNAP usage</Button>
                    <div style={{width: '175px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <Button style={{width: '175px', height: '40px', backgroundColor: currentData === 1 || currentData === 2? colors.progressTeal : colors.tableRowTeal, color: currentData === 1 || currentData === 2? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '10px', fontSize: '16px'}} onClick={()=>{
                            setCurrentChartType('pie')
                            setCurrentData(1)
                            }}>Poverty Rate</Button>
                        {currentData === 1 || currentData === 2 ? 
                            <Button style={{width: '175px', backgroundColor: currentData === 1 ? colors.selectedDeepBlue : colors.notStartedYellow, color: currentData === 1 ? colors.backgroundWhite : colors.forthcomingCharcoal, padding: '5px', margin: '0px', fontSize: '12px'}} onClick={()=>{
                                setCurrentChartType('bar')
                                setCurrentData(2)}}>Broken Down By Race</Button>: null
                        }
                    </div>
                    </div>
                </div>
                <div style={{textAlign: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: '50px'}}>
                    <CityComparisonChart unit={'%'} dataDimension={currentData === 0 ? 'SNAP Users' : 'Poverty Rate'} data={currentData === 0 ? snapData : povertyData} color={colors.goalNavy}/>
                </div>
            </div>
        </article>
    </>
  );
}

export default GoalOneCharts;