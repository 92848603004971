export const colors = {
    headerBlue: '#0164A5',
    progressTeal: '#00B8C7',
    notStartedYellow: '#FCC62E',
    completedGreen: '#A1AE04',
    forthcomingCharcoal: '#7F7F7F',
    goalNavy: '#335676',
    backgroundWhite: '#FEFEFE',
    tableRowTeal: '#E4F7F9',
    accentGrey: '#EBEBEB',
    objectiveOneTeal: '#06BAC9',
    objectiveTwoTeal: '#73D0D8',
    objectiveThreeTeal: '#A1D9DE',
    selectedDeepBlue: '#003964'
}