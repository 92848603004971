import React from 'react';

const CEDSIntro = () => {
    const introText = {
        title: 'Greater Portland CEDS',
        headline: "The Greater Portland Comprehensive Economic Development Strategy (CEDS) was developed to engage community leaders, leverage the involvement of private-sector partners, and establish a blueprint for regional collaboration.",
        supporting: "The CEDS is aimed at regional, city and county economic development practitioners, business leaders, elected officials and stakeholders implementing programs that support the growth of businesses and enhance opportunities for individuals to access economic mobility in the Greater Portland region. However, the goal of the CEDS is to advance equitable job and wealth creation across the region. This requires alignment and implementation of actions across government agencies and organizations. It provides strategic direction to guide actions that will evolve over the next five years to meet dynamic economic and business conditions. The strategy is constant; the actions and organizations involved in implementation will change over time.",
        valuesIntro: "The CEDS strategy is shaped by a set of values and guiding principles that will guide regional efforts for the next 5-10 years.",
        values: ["Equitable economic growth", "Comprehensive skills development", "Innovation and Creativity", "A sustainable environment", "Resilient regional investments", "Creating a favorable environment for businesses to thrive", "Expanding job opportunities in both traded and local sectors"],
        principles: ["A cultural and racial equity lens will ground all objectives and actions to actively respond to the increasing income inequalities exacerbated by the pandemic.", "The private sector will be actively engaged to effectively implement objectives in collaboration with public and nonprofit sectors.","Clear and transparent roles and lead organizations are identified to efficiently implement the plan.","Collaborative bi-state leadership th recognizes and values different community assets (e.g., urban and rural) and will actively promote and invest in the CEDS and implementation efforts."]
    }

  return (
    <article className="ceds-intro-container" style={{width: '100%', textAlign: 'left'}}>
      <section className='full-width-mobile' style={{fontSize: '18px', width: '100%'}}>
        <h1 style={{fontSize: '40px'}}>{introText.title}</h1>
        <h2 style={{fontSize: '26px', color:'#01669F', fontWeight: 'bold'}}>{introText.headline}</h2>
        <p style={{lineHeight: '150%'}}>{introText.supporting}</p>
      </section>
    </article>
  );
}

export default CEDSIntro;

