import React from "react";
import ReactECharts from "echarts-for-react";
import { colors } from "../../utils/style/colors";


const Rose = ({progressSummary}) => {

const progressScores = ['Forthcoming', 'Forthcoming','Not Started', 'Not Started', 'Overcome by Events', 'In Progress', 'Complete']
const progressData = progressSummary.map(p => {
    return progressScores[p]
})
const counts = [
    ["Forthcoming", progressData.filter(p => p === 'Forthcoming').length, colors.forthcomingCharcoal],
    ["Missing Data", progressData.filter(p => p === 'Missing Data').length, '#fade91'],
    ["Missing Data", progressData.filter(p => p === 'Not Started').length, '#fade91'],
    ["Overcome by Events", progressData.filter(p => p === 'Overcome by Events').length, colors.notStartedYellow],
    ["In Progress", progressData.filter(p => p === 'In Progress').length, colors.progressTeal],
    ["Complete", progressData.filter(p => p === 'Complete').length, colors.completedGreen]
]
const data = counts.filter(c => c[1] > 0).map(group => {
    return { 
        value: group[1], 
        name: group[0],
        itemStyle: {
            color: group[2]
        }  
    }
})

const option = {
        backgroundColor: 'transparent',
        title: {
          text: 'Action Item Status',
          subtext: 'hover to see the number of action items in a given category',
          left: 'center',
          padding: 0,
          top: '60'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Action Items',
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            data: data,
            roseType: 'area',
            label: {
              color: '#335676'
            },
            labelLine: {
              lineStyle: {
                color: '#335676'
              },
              smooth: 0.2,
              length: 10,
              length2: 15
            },
            itemStyle: {
              shadowBlur: 100,
              shadowColor: '#E4F7F9',
              shadowStyle: ''
            },
            showEmptyCircle: false,
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
      };

 

  return (
    <ReactECharts option={option} style={{ width: '600px', height: '500px'}} />
  );
}

const RoseProgressChart = React.memo(Rose);

export default RoseProgressChart;

