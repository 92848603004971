import React, { useRef } from 'react';
import { goals } from './data';
import { goalOneIcon, goalTwoIcon, goalThreeIcon, viewBoxOne, viewBoxTwo, viewBoxThree } from '../../images/goalIcons';
import { motion, useScroll, useTransform } from "framer-motion";
import { colors } from '../../utils/style/colors';

const GoalIntro = ({goal}) => {
  const ref = useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["end end", "start start"]
    });
    const opacity = useTransform(
      scrollYProgress,
      [0, 0.22],
      [0.5, 1]
    )

    const goalData = goals.find(g => g.goal === goal);

    const icons = [goalOneIcon, goalTwoIcon, goalThreeIcon];
    const views = [viewBoxOne, viewBoxTwo, viewBoxThree]

  return (
    <article className="goal-intro-container" style={{width: '100%', textAlign: 'left'}}>
        <motion.div 
                    whileHover={{ scale: 1.05 }} 
                    className="goal-circle" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.goalNavy, borderRadius: '50%', width: '120px', height: '120px', margin: '20px', opacity: opacity}}>
                    <svg className="goal-icon" style={{width: '80px', fill: colors.backgroundWhite}} viewBox={views[goal -1]}>
                        {icons[goal - 1]}
                    </svg>
        </motion.div>
        <a id={`${goal}-intro`}></a>
      <section style={{fontSize: '18px', lineHeight: '150%'}}>
        <h1 ref={ref} style={{fontSize: '30px'}}>{`GOAL ${goal}: ${goalData.title.toUpperCase()}`}</h1>
        {goalData.introText}
      </section>
    </article>
  );
}

export default GoalIntro;