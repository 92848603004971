
import React, {useEffect, useState} from "react";
import ReactECharts from "echarts-for-react";
import { colors } from "../../utils/style/colors";
import { barOptionRace } from "./barChartOptions";

const Bar = ({unit, dataDimension, data, color}) => {
    const dataWithColor = data.map(d => {
      const dC = [...d, d[0] === 'Greater Portland' ? color : colors.forthcomingCharcoal];
      return dC;
    })

const widths = ['800px', '600px'];

const option = {
    dataset: [
      {
        dimensions: ['city', dataDimension, "color"],
        source: dataWithColor
      },
      {
        transform: {
          type: 'sort',
          config: { dimension: dataDimension, order: 'desc' }
        }
      }
    ],
    xAxis: {
      type: 'category',
      axisLabel: { interval: 0, rotate: 30 },
      datasetIndex: 0,
    },
    yAxis: {
      axisLabel: {
        formatter: `{value}${unit}`
      }
    },
    color: dataWithColor.sort((a,b) => b[1]-a[1]).map(d => d[2]),
    series: {
        type: 'bar',
        encode: { x: 'city', y: dataDimension },
        datasetIndex: 1,
        colorBy: 'data'
      }
  };

    
    return (
        <ReactECharts 
        option={option} 
        style={{ width: widths[0], height: '300px'}} 
        />
      );
    }
    
const CityComparisonChart = React.memo(Bar);
    
export default CityComparisonChart;
    