import './App.css';
import React, {useState, useRef, useEffect} from 'react';
import ActionTable from './components/ActionTable/ActionTable';
import ActionTableHeader from './components/ActionTableHeader/ActionTableHeader';
import Header from './components/Header/Header';
import Story from './components/Story/Story';
// import DemData from './components/DemData';
// import Triangles from './components/Triangles/Triangles';
import { colors } from './utils/style/colors';
import Button from '@mui/material/Button';
import { motion, useScroll, useTransform } from "framer-motion";
import downloadIcon from './images/icon_download.svg';
import { actions, orgContacts, stories } from './components/Story/data';
import { transformProgressData, getProgressData, getStoryData, getOrgContactData, getPDXData, getSettingsData, getAllData } from './utils/data/api';

function App() {

  const [goal, setGoal] = useState('');
  const [objective, setObjective] = useState(1.1);
  const [page, setPage] = useState('story');
  const [actionData, setActionsData] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [settings, setSettingsData] = useState({"Filter stories by objective": false});
  const [loadingActions, setLoadingActions] = useState(true);
  const [loadingOrgs, setLoadingOrgs] = useState(true);
  const [loadingStories, setLoadingStories] = useState(true);

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["end end", "start start"]
    });

    const opacity = useTransform(
      scrollYProgress,
      [0, 0.9, 1],
      [0, 0, 1]
    )

    useEffect(() => {
      const fetchData = async () => {
          const data = await getProgressData();
          if(data){
            const transformedData = transformProgressData(data.data);
            setActionsData(transformedData);
          } else {
            console.log("using placeholder actions data", actions)
            const fillerData = actions;
            setActionsData(fillerData);
          }
          setLoadingActions(false);
      }
      fetchData();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
          const orgContactData = await getOrgContactData();
          if(orgContactData){
            const filteredContactInfo = orgContactData.filter((org) => org["emailOne"] || org["emailTwo"] || org["website"]);
            setOrgData(filteredContactInfo);
          } else {
            console.log("using placeholder org contact data", orgContacts)
            const fillerData = orgContacts;
            setOrgData(fillerData);
          }
          setLoadingOrgs(false);
      }
      fetchData();
    }, []);

// useEffect(() => {
//   const fetchData = async () => {
//       const actions  = await getProgressData();
//       getOrgContactData();
//       getSettingsData();
//       getPDXData();
//       setActionsData(actions);
//       if(actions.length > 0){setLoadingActions(false)}
//   }
//   fetchData();
// }, []);

useEffect(() => {
  const fetchData = async () => {
      const storyFetch = await getStoryData();
      if(storyFetch){ 
        setStoryData(storyFetch);
        if(storyFetch.length > 0){setLoadingStories(false)}
      } else {
        console.log("using placeholder story data", stories)
        setStoryData(stories);
        if(stories.length > 0){setLoadingStories(false)}
      }
  }
  fetchData();
}, []);


// useEffect(() => {
//   const fetchData = async () => {
//       const settings  = await getSettingsData();
//       if(settings){
//         setSettingsData(settings)
//       };
//   }
//   fetchData();
// }, []);


  return (
    <div className="App" style={{backgroundColor: colors.backgroundWhite}}>
      <Header />
      <ActionTableHeader setGoal={setGoal} setObjective={setObjective} page={page} goal={goal}/>
      <div ref={ref} style={{width: '100vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '0px', height: '50px', backgroundColor: colors.selectedDeepBlue, position: 'sticky', top: '0px', zIndex: '2'}}>
         {page === 'story' && <motion.div className='full-width-mobile' style={{width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '5%', opacity: opacity}}>
            <Button variant="text" style={{marginRight: '40px', height: '30px', color: goal === 1 ?colors.progressTeal : colors.backgroundWhite, fontWeight: 'bold'}} onClick={()=>{
                  setObjective(1.1);
                  setGoal(1);
              }}>GOAL 1</Button>
            <Button variant="text" style={{marginRight: '40px', height: '30px', color: goal === 2 ?colors.progressTeal : colors.backgroundWhite, fontWeight: 'bold'}} onClick={()=>{
                  setObjective(2.1);
                  setGoal(2);
              }}>GOAL 2</Button>
            <Button variant="text" style={{marginRight: '40px', height: '30px', color: goal === 3 ?colors.progressTeal : colors.backgroundWhite, fontWeight: 'bold'}} onClick={()=>{
                  setObjective(3.1);
                  setGoal(3);
              }}>GOAL 3</Button>
          </motion.div>}
          {page === 'table' &&
            <div style={{width: '40%'}}></div>
          }
        <div className="no-mobile" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Button variant="text" style={{minWidth: '190px', marginRight: '40px', height: '30px', color: colors.backgroundWhite}} onClick={()=>{
            setPage(page === 'story' ? 'table' : 'story')
            setGoal('')
            }}>{page === 'story' ? 'View Progress Table' : 'Return to CEDS Overview'}</Button>
          <Button variant="text" style={{marginRight: '40px', minWidth: '250px', height: '30px', color: colors.backgroundWhite}} href="https://www.greaterportlandinc.com/media/userfiles/subsite_214/files/ceds-final-document.pdf" target="_blank"><img style={{height: '25px', margin: '5px'}} src={downloadIcon}/>Download CEDS PDF</Button>
        </div>
      </div>
      <h3 className='mobile-only'>This page is not yet optimized for mobile devices. Consider viewing it on a larger screen to interact with all features.</h3>
      {(page === 'table' && (!loadingActions && !loadingOrgs)) &&
        <ActionTable orgContacts={orgData} progress={actionData}/>
      }
      {(page === 'story' && !loadingActions && !loadingStories) && 
        <Story stories={storyData} actions={actionData} goal={goal === "" ? 1 : goal} objective={objective} setObjective={setObjective}/>
      }
    </div>
  );
}

export default App;
