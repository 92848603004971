import sineImg from '../../images/sine.png';
import sportImg from '../../images/stateOfSport.png';
import occa from '../../images/occa.png';

export const goals = [
    {
        goal: 1,
        title: 'Foster Upward Mobility',
        introText:<p className='full-width-mobile' style={{width: '100%'}}>Wealth creation matters for five reasons. First, wealth creation is a safety net that keeps households from being derailed by temporary setbacks and the loss of income. Second, it allows people to take career risks knowing that they have a buffer when success is not immediately achieved. Third, family wealth affords residents access to housing in neighborhoods with good schools, thereby enhancing the upward mobility of their own children. Fourth, wealth creation matters to improve outcomes relative to the social determinants of health. In short, people with wealth enjoy longer and healthier lives. Finally, wealth creation affords people opportunities to be entrepreneurs and inventors; the income from wealth is taxed at much lower rates than income from work, which means that wealth begets more wealth.</p>
    },
    {
        goal: 2,
        title: "Support a Competitive Economy",
        introText: <section style={{width: '100%'}}>
        <div style={{width: '100%'}}>
            <p>The job of economic development professionals is not to pick which idea or technology may succeed, but to create a fertile ground where innovation will thrive locally and become a pipeline to the region’s next emerging sector. Greater Portland is well positioned to foster innovation in hightech, R&D “advanced” industries that can address climate change and support the vital technology underpinning the future economy. Annually, the region, on average, attracts $365 million in venture capital, according to analysis by the Center for American Entrepreneurship. The Brookings Institution estimates that nearly 5% of Greater Portland employees work in innovation industries - a share that is on par with tech hubs Austin, Texas and San Diego, California.</p>
            <p>In developing tactical actions to support small business growth, it is important to recognize that small businesses are not uniform and those in different size categories face different challenges in accessing financing and technical support, and not all small businesses are positioned to scale. As identified by TEConomy Partners, LLC, while most entrepreneurs start by forming small businesses, not all small businesses are entrepreneurial. Small business owners develop companies to generate wealth and provide employment and income for themselves and others; entrepreneurs are interested in creating innovative products or services that lead to further investment and growth. Understanding the different motivations and needed support services for these two types of businesses is important in developing the strategy and actions.</p>
        </div>
        <div style={{width: '100%'}}>
            <p>The unequal accrual of wealth via economic growth opportunities creates challenges for many existing small businesses as both the workforce and customer base they depend on become priced out of the community. Rising costs in an economy where economic opportunity is not rising at the same rate places limits on the types and varieties of locally-owned businesses, especially those operated by BIPOC individuals. To encourage economic growth across business types and sectors, economic development actions should recognize and respond to both the direct needs of businesses as well as the broader economic conditions that are limiting potential for success.</p>
            <p>In order to have a holistic approach to our region’s economic development efforts, we must evaluate both the benefits and costs of economic development strategies to communities. Such a holistic approach is integral to the cultural and racial equity lens we use to guide our work.</p>
        </div>
        </section>
    },
    {
        goal: 3,
        title: "Build a Resilient Region",
        introText: <p style={{width: '100%'}}>Resilience and economic growth are both vital for healthy economies in uncertain times. If a local economy is resilient, the recovery period will be much shorter than an economy with little or no resilience. If a local economy is not resilient, it may never return to its former economic growth path. Places that recovered from the 2008-2009 Great Recession included regions with high educational attainment, a diverse and adaptable workforce, attainable housing prices and rents, ongoing capital investment indicating the availability of significant public resources, and those that are not dependent on the cyclical nature of a single industry. Likewise, resilient regions invest equally in addressing chronic stressors associated with economic inequality, which plagues all of our communities: poverty, limited education attainment, unemployment, health disparities, racial biases and other factors. As the current pandemic is showing, disasters disproportionately impact our most vulnerable populations. Regions will need to address those left behind and hit the hardest before they can jump forward. </p>   
    }
]
export const objectives = [
    {
        goal:1,
        objective:1.1,
        description:"Provide comprehensive and coordinated skills development starting with child care through higher-ed, including vocational, post-secondary, and life-long learning",
        supportingText: <p>Providing early learning child care is a critical community investment, helping to develop the necessary skills that allow a community to realize its human and economic potential. This is especially true for underrepresented children. Business leaders across all regional industries - metals and machinery, computer software, apparel and outdoor and others - want to start engaging students in middle school to prepare the local future workforce. This was evident in every industry focus group hosted during the CEDS engagement process. Higher education leaders in Greater Portland intend to build off this engagement and provide necessary skills development through college. Improving the skills of individuals not only improves the employment base, but also creates civic and business leaders that will guide the region in the future economy.</p>
    },
    {
        goal:1,
        objective:1.2,
        description:"Expand economic opportunities for all individuals with a focus on the BIPOC and underrepresented communities",
        supportingText: <p>Expanding access to quality job opportunities increases economic opportunity and allows individuals to build wealth and begin to close the income gap. There are current opportunities within the health care and construction industries that should be leveraged. Industries such as software and design and media, which have minimal automation impacts and strong wages but are challenging to access, should be a focus for improved access especially for underrepresented individuals.</p>
    },
    {
        goal:2,
        objective:2.1,
        description:"Develop an entrepreneurial ecosystem that supports robust local small businesses and scales traded sector industry",
        supportingText: <><p>New business growth in Oregon has been stagnant since 2017 but is growing in Washington. According to the Kauffman Foundation, a thought leader in growing and sustaining entrepreneurship across the U.S., approximately 1% of Portland small businesses scale to “medium-sized” enterprises after 10 years. This ranks last among Portland’s peer communities.</p>
        <p>The impacts of COVID-19 suggest new businesses will be emerging out of necessity. There is an opportunity to increase the support of scaling businesses tied to innovation with strategic investments. Additionally, focused contracting and buying from local firms, especially BIPOC owned, through procurement policies among major anchor institutions such as health care and educational institutions and large traded-sector businesses should be utilized more broadly. Less than 1 in 5 startups in Portland are BIPOC-owned, ranking near the bottom of peer communities. This statistic alone is a call to action for the region to support increased investments in underrepresented enterprises.</p></>
    },
    {
        goal:2,
        objective:2.2,
        description:"Invest in innovations that strengthen and grow the Portland region traded sector clusters",
        supportingText: <p>Oregon’s footwear and apparel cluster has been a key driver of the region’s innovation, per an analysis from Harvard’s Institute of Competitiveness and Strategy’s U.S. Cluster Mapping Project. To ensure a more resilient future, innovation within all regional sectors must be expected. Small fabricators will require automation equipment and an increasingly skilled workforce to remain competitive and stay in the region. Meanwhile, the computer and electronics (C&E) industry supply chain is increasingly solidifying its presence in Asia. Global economic disruptions like these require a diversity of industry types, sizes and talent to foster a resilient regional economic base. Ongoing and enhanced commitment from Greater Portland’s business leadership to hire diverse talent will improve innovation and resiliency and strengthen the likelihood of improved financial performance for local enterprises.</p>
    },
    {
        goal:2,
        objective:2.3,
        description:"Enhance the Portland regional identity and brand for talent, capital and business",
        supportingText: <p>Several regional industries, specifically
        the apparel and outdoor and software
        sectors, indicated that retaining
        diverse, especially BIPOC, talent is their
        most important issue.
        However, according to
        the Partners in Diversity
        Retention Project,
        professionals of color
        convey there is an
        unwelcoming culture
        for diverse individuals
        in the Portland region.
        At the same time, between 2013 and
        2019, venture capital funding in climate
        tech increased 3,750% and the Portland
        region can genuinely leverage its brand
        and policies to access this opportunity
        for emerging businesses. By some
        estimates, 25% of the U.S. workforce
        may remain “remote
        workers” after the pandemic
        with the freedom to pick and
        choose their home locations.
        By addressing barriers
        and elevating authentic
        opportunities that enhance
        a more welcoming regional
        brand, Greater Portland can
        compete for that diverse talent pool,
        while attracting investment to help grow
        its industry base and BIPOC enterprises.</p>
    },
    {
        goal:3,
        objective:3.1,
        description:"Construct resilient physical infrastructure",
        supportingText:<><p>Measured by value, 74% of the
        commodities traveling in the Portland
        region are moved by truck and trucking
        will remain the predominant mode of
        freight transport in the region for the
        foreseeable future. Recent studies on the
        advancement of e-commerce indicate that
        the rise in demand for quick deliveries is
        adjusting how truck freight interacts with
        local distribution networks with fulfillment,
        warehouse and distribution centers located
        in exurban locations within or adjacent
        to the central city. Last-mile carriers have
        increased their workforces, expanded
        hours of operation and fleets, and employ
        not just trucks, but also vans, automobiles
        and bicycles to fulfill customer
        requirements for rapid local distribution. These recent shifts and the necessity of
        moving between modes to meet consumer
        demand elevate the importance of a well connected multi-modal freight system that
        leverages these localized delivery methods
        alongside strong intermodal connections
        between the trucking system and air, rail
        and marine shipping methods. The river system is an
        essential component
        of the region’s major
        freight corridors and
        investments The river system is an essential component
        of the region’s major freight corridors and
        investments that support marine-based
        operations and industry opportunities are
        supported in consultation with the ports
        and industries of the region.</p>
        <p>In addition to freight delivery, high-growth
        industry clusters tend to be located in
        and rely on investments in multi-modal
        transportation infrastructure. This includes
        better traffic management and increased
        rates of carpooling to reduce unmet
        capacity when widening is not feasible,
        involves significant community impact,
        and transit availability is also a competitive
        advantage in attracting skilled workers.</p>
        <p>Rebuilding the I-5 and Burnside bridges,
        which cross the two major rivers in the
        region, and understanding gaps in our
        fuel supply chain and emergency water
        provision plans, are vital for a resilient
        region that can withstand earthquake
        impacts and support the economic base.
        Investments in TriMet and C-TRAN support
        real estate development activity and help
        concentrate economic activity in addition
        to the traditional role providing mobility
        options for workers.</p>
        <p>Additionally, targeted investment in
        other infrastructure such as broadband
        and climate technology innovation
        will contribute to our regional vitality.
        Broadband in underserved communities,
        both urban and rural, will increase resilience
        to economic shocks and disruptions (such
        as demand created by remote learning
        during the COVID-19 pandemic), help
        with recovery and support more robust
        growth. High-speed, quality and affordable
        broadband is critical for businesses and
        households. Furthermore, fostering new
        technologies that address impacts of
        climate change are of critical importance to
        the region.</p>
        </>
    },
    {
        goal:3,
        objective:3.2,
        description:"Strategically invest in public policies that align with economic development priorities",
        supportingText: <><p>
            Businesses require available sites for
            expansion opportunities. The average
            site size needed is 17 acres, according
            to recruitment requests managed by
            GPI over the last five years. Public and
            private collaboration is required to
            provide appropriately sized sites that
            meet the location and infrastructure needs
            while implementing land use policies
            that address climate change. Focused
            alignment with Opportunity Zones,
            especially as the Biden administration
            looks to expand this program, can enhance
            outcomes of targeted site expansion.
            Additionally, continued investment
            in supportive infrastructure such as
            affordable housing and transit is vital
            for underserved individuals to access
            opportunity.</p>
            <p>The COVID-19 pandemic has accelerated
            the adoption of remote work across the
            world. Researchers estimate that 25% of
            workers will remain working remotely
            after the pandemic (up from 2% before
            pandemic). An additional 20% of the
            workforce will do so part-time on a flexible
            basis. Greater Portland should support
            placemaking investments in diverse
            communities throughout the region to
            meet the needs of residents. Placemaking
            fosters public gathering in open, accessible
            areas such as public parks or private retail
            and restaurants. Such environments may
            encourage remote workers to live and
            work in suburban or rural communities to
            reduce commuting and climate impacts.</p></>
    }
]

export const actions = [
    {
      goal: 1,
      objective: 1.1,
      action: 'A. Support and scale the Shared Service Alliance (SSA) pilot program to share business administration for 50 child care programs. Automate child care management software, develop a backbone agency, and implement the business coaching framework.',
      outcome: 'Automate support for child care programs',
      metric: 'A regional care organization exists and it has dedicated staff',
      years: {
                2022: {
                    1:[{org:'Shared Service Alliance', status:'na'}],
                    2:[{org:'Shared Service Alliance', status:'na'}],
                    3:[{org:'Shared Service Alliance', status:'na'}],
                    4:[{org:'Shared Service Alliance', status:'missingData'}],
                },
                2023: {
                    1:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    2:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    3:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    4:[{org:'Shared Service Alliance', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    2:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    3:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    4:[{org:'Shared Service Alliance', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    2:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    3:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    4:[{org:'Shared Service Alliance', status:'forthcoming'}]
                },
                2026: {
                    1:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    2:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    3:[{org:'Shared Service Alliance', status:'forthcoming'}],
                    4:[{org:'Shared Service Alliance', status:'forthcoming'}]
                },
        },
        potentialPartners: ['Oregon Community Foundation', 'Social Venture Partners', 'CCR&R', 'Early Learning Division', 'United Way', 'Neighborhood House']
    },
    {
        goal: 1,
        objective: 1.1,
    action: 'B. Develop and scale a work group around student engagement to leverage state education agency and foundation dollars to address attendance and enrollment decline for PK-12 students of color.',
     outcome: 'BIPOC students (PK-12) are enrolled in school with intentional support for engagement',
     metric: 'Number of BIPOC students enrolled in school with regular attendance',
     years: {
                2022: {
                    1:[{org:'United Way', status:'na'}],
                    2:[{org:'United Way', status:'na'}],
                    3:[{org:'United Way', status:'na'}],
                    4:[{org:'United Way', status:'missingData'}],
                },
                2023: {
                    1:[{org:'United Way', status:'forthcoming'}],
                    2:[{org:'United Way', status:'forthcoming'}],
                    3:[{org:'United Way', status:'forthcoming'}],
                    4:[{org:'United Way', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'United Way', status:'na'}],
                    2:[{org:'United Way', status:'na'}],
                    3:[{org:'United Way', status:'na'}],
                    4:[{org:'United Way', status:'na'}]
                },
                2025: {
                    1:[{org:'United Way', status:'na'}],
                    2:[{org:'United Way', status:'na'}],
                    3:[{org:'United Way', status:'na'}],
                    4:[{org:'United Way', status:'na'}]
                },
                2026: {
                    1:[{org:'United Way', status:'na'}],
                    2:[{org:'United Way', status:'na'}],
                    3:[{org:'United Way', status:'na'}],
                    4:[{org:'United Way', status:'na'}]
                },
        }, 
        potentialPartners: ['All Hands Raised', 'School Districts', 'ELD', 'ODE']
   },
   {
    goal: 1,
    objective: 1.1,
    action: 'C. Convene regional STEM organizations to inventory current programs offered to connect students with employers and identify gaps. Consider a bi-state regional marketplace for employers and K-12 schools to expose students to STEM and STEAM opportunities, particularly those from BIPOC communities to interact with professionals through coordinated collaboration. Also work with regional employers on company culture to ensure BIPOC employees are retained and advanced in careers.',
     outcome: 'More K-12 BIPOC students are exposed to STEM and STEAM opportunities with regional employers',
     metric: 'Number of BIPOC students engaged in STEM and STEAM programming',
     years: {
                2022: {
                    1:[{org:'East Metro STEAM', status:'na'}, {org:'Oregon STEM Hubs', status:'na'}, {org:'Career Connect SW WA', status:'na'}],
                    2:[{org:'East Metro STEAM', status:'na'},{org:'Oregon STEM Hubs', status:'na'}, {org:'Career Connect SW WA', status:'na'}],
                    3:[{org:'East Metro STEAM', status:'na'},{org:'Oregon STEM Hubs', status:'na'}, {org:'Career Connect SW WA', status:'na'}],
                    4:[{org:'East Metro STEAM', status:'na'},{org:'Oregon STEM Hubs', status:'na'}, {org:'Career Connect SW WA', status:'na'}],
                },
                2023: {
                    1:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    2:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    3:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    4:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    2:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    3:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    4:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    2:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    3:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    4:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}]
                },
                2026: {
                    1:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    2:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    3:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}],
                    4:[{org:'East Metro STEAM', status:'forthcoming'},{org:'Oregon STEM Hubs', status:'forthcoming'}, {org:'Career Connect SW WA', status:'forthcoming'}]
                },
        },
        potentialPartners: ['OBC STEM Committee', 'WorkReady Program', 'TiE Oregon', 'MESO', 'CTE', 'United Way', 'NW Natural', 'PGE', 'Economic Development Partners', 'City of Hillsboro']
   },
   {
    goal: 1,
    objective: 1.1,
    action: 'D. Advance the Quality Jobs Initiative to design and develop a regional approach to help employers introduce, change and leverage business practices to make living-wage, safe, and family-supporting jobs better and foster positive outcomes for their employees, specifically consulting with and supporting impacted front-line workers.',
    outcome: 'Increase workforce program participant hires by businesses who meet Quality Jobs standards',
    metric: 'Number of workforce program hires by businesses engaged in Quality Jobs Initiative',
    years: {
        2022: {
            1:[{org:'Columbia-Willamette Workforce Collab', status:'na'}],
            2:[{org:'Columbia-Willamette Workforce Collab', status:'na'}],
            3:[{org:'Columbia-Willamette Workforce Collab', status:'na'}],
            4:[{org:'Columbia-Willamette Workforce Collab', status:'inProgress'}],
        },
        2023: {
            1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
        },
        2024: {
            1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
        },
        2025: {
            1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
        },
        2026: {
            1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
            4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
        },
    },
    potentialPartners: ['AFL-CIO']
  },
  {
    goal: 1,
    objective: 1.1,
    action: 'E. Evaluate gaps and needs with partnerships between Greater Portland’s K-12 and higher education leaders to identify and develop opportunities for intentional engagement. Research national models and best practices for K-12/higher education partnerships to emulate in our region.',
    outcome: 'Enhance early skills development to prepare for higher education opportunities',
    metric: 'New programs identified and implemented',
    years: {
        2022: {
            1:[{org:'GPI Higher Education Committee', status:'na'}],
            2:[{org:'GPI Higher Education Committee', status:'na'}],
            3:[{org:'GPI Higher Education Committee', status:'na'}],
            4:[{org:'GPI Higher Education Committee', status:'na'}],
        },
        2023: {
            1:[{org:'GPI Higher Education Committee', status:'na'}],
            2:[{org:'GPI Higher Education Committee', status:'na'}],
            3:[{org:'GPI Higher Education Committee', status:'na'}],
            4:[{org:'GPI Higher Education Committee', status:'na'}]
        },
        2024: {
            1:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            2:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            3:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            4:[{org:'GPI Higher Education Committee', status:'forthcoming'}]
        },
        2025: {
            1:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            2:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            3:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            4:[{org:'GPI Higher Education Committee', status:'forthcoming'}]
        },
        2026: {
            1:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            2:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            3:[{org:'GPI Higher Education Committee', status:'forthcoming'}],
            4:[{org:'GPI Higher Education Committee', status:'forthcoming'}]
        },
    },
    potentialPartners: ['K-12 districts', 'All Hands Raised', 'Education NW',
        'Adelante Chicas', 'TRIO',
        'United Way', 'City of Hillsboro']
},
  {
    goal: 1,
    objective: 1.1,
    action: 'F. Expand the capacity of Community Based Organizations and Non-profits through technical support and incubation. Early focus will address challenges resulting from the COVID-19 pandemic and subsequent recovery',
    outcome: 'Develop a regional incubator to support non-profit organizations through incubators and accelerators',
    metric: 'Provide funding and technical support to multiple cohorts of strategically selected nonprofits',years: {
        2022: {
            1:[{org:'Oregon Technology Business Center', status:'na'}],
            2:[{org:'Oregon Technology Business Center', status:'na'}],
            3:[{org:'Oregon Technology Business Center', status:'na'}],
            4:[{org:'Oregon Technology Business Center', status:'missingData'}],
        },
        2023: {
            1:[{org:'Oregon Technology Business Center', status:'forthcoming'}],
            2:[{org:'Oregon Technology Business Center', status:'forthcoming'}],
            3:[{org:'Oregon Technology Business Center', status:'forthcoming'}],
            4:[{org:'Oregon Technology Business Center', status:'forthcoming'}]
        },
        2024: {
            1:[{org:'Oregon Technology Business Center', status:'forthcoming'}],
            2:[{org:'Oregon Technology Business Center', status:'forthcoming'}],
            3:[{org:'Oregon Technology Business Center', status:'forthcoming'}],
            4:[{org:'Oregon Technology Business Center', status:'forthcoming'}]
        },
        2025: {
            1:[{org:'Oregon Technology Business Center', status:'na'}],
            2:[{org:'Oregon Technology Business Center', status:'na'}],
            3:[{org:'Oregon Technology Business Center', status:'na'}],
            4:[{org:'Oregon Technology Business Center', status:'na'}]
        },
        2026: {
            1:[{org:'Oregon Technology Business Center', status:'na'}],
            2:[{org:'Oregon Technology Business Center', status:'na'}],
            3:[{org:'Oregon Technology Business Center', status:'na'}],
            4:[{org:'Oregon Technology Business Center', status:'na'}]
        },
    },
    potentialPartners: ['Oregon Association of NonProfits', 'foundations']
  },
  {
    goal: 1,
    objective: 1.1,
    action: 'G. Advance Greater Portland Economic Recovery Plan recommendations for Target Impact Area 2 (Advance economic mobility for individuals) and Target Impact Area 3 (Support families and children)',
    outcome: 'See Economic Recovery Plan',
    metric: 'See Economic Recovery Plan',
    years: {
                2022: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'inProgress'}],
                },
                2023: {
                    1:[{org:'GPI', status:'forthcoming'}],
                    2:[{org:'GPI', status:'forthcoming'}],
                    3:[{org:'GPI', status:'forthcoming'}],
                    4:[{org:'GPI', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}]
                },
                2025: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}]
                },
                2026: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}]
                },
        },
        potentialPartners: ['Varied']
    },
  {
    goal: 1,
    objective: 1.2,
    action: 'A. Secure resources to advance five-year goals of SINE (Survival Is Not Enough) to create a more diverse and inclusive region. Investments in SINE will provide opportunities to college graduates from communities of color and rural areas because the future of our communities’ hinges on the leaders of our organizations reflecting the people who live here.',
    outcome: 'More BIPOC college graduates are exposed to job opportunities with regional employers',
    metric: 'Track number of BIPOC participants in the program',
    years: {
                2022: {
                    1:[{org:'SINE', status:'na'}],
                    2:[{org:'SINE', status:'na'}],
                    3:[{org:'SINE', status:'na'}],
                    4:[{org:'SINE', status:'missingData'}],
                },
                2023: {
                    1:[{org:'SINE', status:'forthcoming'}],
                    2:[{org:'SINE', status:'forthcoming'}],
                    3:[{org:'SINE', status:'forthcoming'}],
                    4:[{org:'SINE', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'SINE', status:'forthcoming'}],
                    2:[{org:'SINE', status:'forthcoming'}],
                    3:[{org:'SINE', status:'forthcoming'}],
                    4:[{org:'SINE', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'SINE', status:'forthcoming'}],
                    2:[{org:'SINE', status:'forthcoming'}],
                    3:[{org:'SINE', status:'forthcoming'}],
                    4:[{org:'SINE', status:'forthcoming'}]
                },
                2026: {
                    1:[{org:'SINE', status:'forthcoming'}],
                    2:[{org:'SINE', status:'forthcoming'}],
                    3:[{org:'SINE', status:'forthcoming'}],
                    4:[{org:'SINE', status:'forthcoming'}]
                },
        },
        potentialPartners: ['GPI']
  },
  {
      goal: 1,
      objective: 1.2,
      action: 'B. Increase business support for Future Leaders Project (FLP) as a long-term strategy for ensuring business and community leaders are more representative of all the people that live in Southwest Washington. FLP provides college students from historically underserved communities a platform to build social capital and gain professional development through paid internships and mentoring from business, non-profit and public leaders.',
      outcome: 'More BIPOC college students are exposed to job opportunities with regional employers',
      metric: 'Track number of BIPOC participants and participants from historically underrepresented communities in the program',
      years: {
                2022: {
                    1:[{org:'WSU Vancouver', status:'na'}, {org:'CREDC', status:'na'}, {org:'Workforce SW Washington', status:'na'}],
                    2:[{org:'WSU Vancouver', status:'na'}, {org:'CREDC', status:'na'}, {org:'Workforce SW Washington', status:'na'}],
                    3:[{org:'WSU Vancouver', status:'na'}, {org:'CREDC', status:'na'}, {org:'Workforce SW Washington', status:'na'}],
                    4:[{org:'WSU Vancouver', status:'inProgress'}, {org:'CREDC', status:'missingData'}, {org:'Workforce SW Washington', status:'missingData'}],
                },
                2023: {
                    1:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    2:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    3:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    4:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    2:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    3:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    4:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    2:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    3:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    4:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}]
                },
                2026: {
                    1:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    2:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    3:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}],
                    4:[{org:'WSU Vancouver', status:'forthcoming'}, {org:'CREDC', status:'forthcoming'}, {org:'Workforce SW Washington', status:'forthcoming'}]
                },
        },
        potentialPartners: ['CREDC', 'Workforce SW WA']
 },
 {
      goal: 1,
      objective: 1.2,
      action: 'C. Expand Construction and Health Care industry workforce programs through additional funding and support.',
      outcome: 'Increase job opportunities, particularly for BIPOC individuals, within these industries',
      metric: 'Report number of jobs within the industries and percentage of BIPOC hires',
      years: {
            2022: {
                1:[{org:'Columbia-Willamette Workforce Collab', status:'na'}],
                2:[{org:'Columbia-Willamette Workforce Collab', status:'na'}],
                3:[{org:'Columbia-Willamette Workforce Collab', status:'na'}],
                4:[{org:'Columbia-Willamette Workforce Collab', status:'inProgress'}],
            },
            2023: {
                1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
            },
            2024: {
                1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
            },
            2025: {
                1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
            },
            2026: {
                1:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                2:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                3:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}],
                4:[{org:'Columbia-Willamette Workforce Collab', status:'forthcoming'}]
            },
        },
        potentialPartners: ['PBDG', 'Metro', 'Port of Portland', 'NW Natural', 'PGE', 'AFL-CIO']
 },
 {
      goal: 1,
      objective: 1.2,
      action: 'D. Encourage development of a unifying umbrella to facilitate management of microenterprises with financial support via a statewide office for micro/small businesses. This management will help existing micro-enterprise organizations provide information, guidance and support for clients to expand, recruit and retain BIPOC talent. It is not envisioned that the management organization would provide direct services.',
      outcome: 'Provide coordination of existing micro/ small business organizations to enhance efficiencies and sustainable funding',
      metric: 'A statewide staff person/program is identified to meet this need.',
      years: {
                2022: {
                    1:[{org:'Community Chamber Coalition of Oregon', status:'na'}],
                    2:[{org:'Community Chamber Coalition of Oregon', status:'na'}],
                    3:[{org:'Community Chamber Coalition of Oregon', status:'na'}],
                    4:[{org:'Community Chamber Coalition of Oregon', status:'inProgress'}],
                },
                2023: {
                    1:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    2:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    3:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    4:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    2:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    3:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    4:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    2:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    3:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    4:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}]
                },
                2026: {
                    1:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    2:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    3:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}],
                    4:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'}]
                },
    },
    potentialPartners: ['MESO', 'Orego Entrepreneurs Network', 'PBDG', 'TiE', 'PCC SBDC', 'Mercy Corps NW', 'Busines Oregon', 'WEDA', 'OEDA', 'Washington County BRC']
},
{
  goal: 1,
  objective: 1.2,
  action: 'E. Increase available debt capital for small businesses who do not have access to conventional bank loans by securing new investors and donors for our region’s CDFIs, including funds designated for loan loss reserves.',
  outcome: 'Increased CDFI lending activity to small business owners throughout the region with an emphasis on women and people of color',
  metric: 'Amount of CDFI lending secured.',
  years: {
                2022: {
                    1:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    2:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    3:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    4:[{org:'Point West Credit Union', status:'missingData'}, {org:'MESO', status:'missingData'}, {org:'Craft3', status:'missingData'}],
                },
                2023: {
                    1:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}],
                    2:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}],
                    3:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}],
                    4:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}],
                    2:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}],
                    3:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}],
                    4:[{org:'Point West Credit Union', status:'forthcoming'}, {org:'MESO', status:'forthcoming'}, {org:'Craft3', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    2:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    3:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    4:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}]
                },
                2026: {
                    1:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    2:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    3:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}],
                    4:[{org:'Point West Credit Union', status:'na'}, {org:'MESO', status:'na'}, {org:'Craft3', status:'na'}]
                },
     },
     potentialPartners: ['BBPDX', 'Communit Chamber Coalition of Oregon', 'Xxcelerate']
},
{
  goal: 1,
  objective: 1.2,
  action: 'F. As identified in the Greater Portland Economic Recovery Plan, develop a responsive regional technical assistance network to more broadly support micro-small businesses and entrepreneurs, after completing Action 2.1.A.',
  outcome: 'Enhance service provision to micro-small businesses and entrepreneurs',
  metric: 'Survey number of businesses served and percentage of BIPOC companies',
  years: {
            2022: {
                1:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                2:[{org:'Community Chamber Coalition of Oregon', status:'na'},{org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                3:[{org:'Community Chamber Coalition of Oregon', status:'na'},{org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                4:[{org:'Community Chamber Coalition of Oregon', status:'inProgress'},{org:'MESO', status:'missingData'}, {org:'PBDG', status:'missingData'}],
            },
            2023: {
                1:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'},{org:'MESO', status:'forthcoming'}, {org:'PBDG', status:'forthcoming'}],
                2:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'},{org:'MESO', status:'forthcoming'}, {org:'PBDG', status:'forthcoming'}],
                3:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'},{org:'MESO', status:'forthcoming'}, {org:'PBDG', status:'forthcoming'}],
                4:[{org:'Community Chamber Coalition of Oregon', status:'forthcoming'},{org:'MESO', status:'forthcoming'}, {org:'PBDG', status:'forthcoming'}]
            },
            2024: {
                1:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                2:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                3:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                4:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}]
            },
            2025: {
                1:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                2:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                3:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                4:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}]
            },
            2026: {
                1:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                2:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                3:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}],
                4:[{org:'Community Chamber Coalition of Oregon', status:'na'}, {org:'MESO', status:'na'}, {org:'PBDG', status:'na'}]
            },
        },
        potentialPartners: ['Oregon Entrepreneurs Network', 'CREDC', 'TiE', 'PCC SBDC', 'Mercy Corps NW', 'Washington County BRC', 'Washington County']
},
{
    goal: 2,
    objective: 2.1,
    action: 'A. Conduct an evaluation of the existing regional system of small business and entrepreneur support services and assistance (ecosystem), including the support of innovation-based startups. This should include an evaluation of the region’s entrepreneurial ecosystem and diagnosis of the factors that led to the region’s low ranking by Kauffman Foundation relative to peer regions. Identify action items based on that diagnosis.',
    outcome: 'Elevate existing programs and identify gaps/needs for new programs',
    metric: 'Stakeholder consensus on investing resources in a regional study',
    years: {
                2022: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'inProgress'}],
                },
                2023: {
                    1:[{org:'GPI', status:'forthcoming'}],
                    2:[{org:'GPI', status:'forthcoming'}],
                    3:[{org:'GPI', status:'forthcoming'}],
                    4:[{org:'GPI', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}]
                },
                2025: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}]
                },
                2026: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}]
                },
        },
        potentialPartners: ['CREDC', 'SBDC', 'local incubators and accelerators', 'MESO', 'Venture Catalyst']
    },
    {
        goal: 2,
        objective: 2.1,
        action: 'A. Conduct an evaluation of the existing regional system of small business and entrepreneur support services and assistance (ecosystem), including the support of innovation-based startups. This should include an evaluation of the region’s entrepreneurial ecosystem and diagnosis of the factors that led to the region’s low ranking by Kauffman Foundation relative to peer regions. Identify action items based on that diagnosis.',
        outcome: 'Elevate existing programs and identify gaps/needs for new programs',
        metric: 'Stakeholder consensus on investing resources in a regional study',
        years: {
                    2022: {
                        1:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        2:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        3:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        4:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                    },
                    2023: {
                        1:[{org:'BBPDX', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                        2:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'BBPDX', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        2:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        3:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        4:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}]
                    },
                    2026: {
                        1:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        2:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        3:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}],
                        4:[{org:'BBPDX', status:'na'}, {org:'GPI', status:'na'}]
                    },
            },
            potentialPartners: ['GPI', 'WA Dept of Commerce',
                'SBDC', 'local incubators and accelerators', 'Venture Catalyst'],
            resources: ['Leverage Program', 'state funding to implement recommended action']
        },
        {
            goal: 2,
            objective: 2.1,
            action: 'B. Determine feasibility of and support for a Regional Innovation Hub designation to incentivize regional collaboration and creation of an innovation-based ecosystem.',
            outcome: 'Innovationbased services delivered through a collaborative and cohesive regional innovation ecosystem',
            metric: 'Increase in networking, connectivity, and collaborative/entrepreneurial culture in the region',
            years: {
                    2022: {
                        1:[{org:'Business Oregon', status:'na'}],
                        2:[{org:'Business Oregon', status:'na'}],
                        3:[{org:'Business Oregon', status:'na'}],
                        4:[{org:'Business Oregon', status:'na'}],
                    },
                    2023: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                    2026: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                },
                potentialPartners: ['GPI', 'WA Dept of Commerce', 'SBDC', 'Oregon Growth Board', 'MESO', 'local incubators and accelerators', 'Venture Catalyst'],
                resources:['state investment in existing programs']
        },
        {
            goal: 2,
            objective: 2.1,
            action: 'C. Foster the further development of Oregon’s risk capital stack per the Oregon Innovation Plan, January 2021 and consider opportunities such as the Angel Investment Tax Credit (Oregon only because credits reflect income tax), expanding CDFI support, and angel, pre-seed, and seed funds, sector-specific risk-capital, and working capital loan funds.',
            outcome: 'Private-sector market gap for investment dollars needed by innovation-based entrepreneurs is addressed',
            metric: 'Additional risk capital created and available in the regional ecosystem',
            years: {
                        2022: {
                            1:[{org:'Business Oregon', status:'na'}],
                            2:[{org:'Business Oregon', status:'na'}],
                            3:[{org:'Business Oregon', status:'na'}],
                            4:[{org:'Business Oregon', status:'na'}],
                        },
                        2023: {
                            1:[{org:'Business Oregon', status:'forthcoming'}],
                            2:[{org:'Business Oregon', status:'forthcoming'}],
                            3:[{org:'Business Oregon', status:'forthcoming'}],
                            4:[{org:'Business Oregon', status:'forthcoming'}]
                        },
                        2024: {
                            1:[{org:'Business Oregon', status:'forthcoming'}],
                            2:[{org:'Business Oregon', status:'forthcoming'}],
                            3:[{org:'Business Oregon', status:'forthcoming'}],
                            4:[{org:'Business Oregon', status:'forthcoming'}]
                        },
                        2025: {
                            1:[{org:'Business Oregon', status:'forthcoming'}],
                            2:[{org:'Business Oregon', status:'forthcoming'}],
                            3:[{org:'Business Oregon', status:'forthcoming'}],
                            4:[{org:'Business Oregon', status:'forthcoming'}]
                        },
                        2026: {
                            1:[{org:'Business Oregon', status:'forthcoming'}],
                            2:[{org:'Business Oregon', status:'forthcoming'}],
                            3:[{org:'Business Oregon', status:'forthcoming'}],
                            4:[{org:'Business Oregon', status:'forthcoming'}]
                        },
            },
            potentialPartners: ['GPI', 'WA Dept of Commerce', 'SBDC', 'Oregon Growth Board', 'MESO', 'local incubators and accelerators', 'Venture Catalyst'],
            resources: ['Leverage Existing; state investment in existing programs']
        },
        {
            goal: 2,
            objective: 2.1,
            action: 'D. Develop customized technical assistance to help companies transition to e-commerce and maintain a continued online presence.',
            outcome: 'Help small retailers diversify into expanded market opportunities',
            metric: 'Number of BIPOC and underrepresented community members completed program',
            years: {
                    2022: {
                        1:[{org:'Prosper Portland', status:'na'}],
                        2:[{org:'Prosper Portland', status:'na'}],
                        3:[{org:'Prosper Portland', status:'na'}],
                        4:[{org:'Prosper Portland', status:'missingData'}],
                    },
                    2023: {
                        1:[{org:'Prosper Portland', status:'forthcoming'}],
                        2:[{org:'Prosper Portland', status:'forthcoming'}],
                        3:[{org:'Prosper Portland', status:'forthcoming'}],
                        4:[{org:'Prosper Portland', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Prosper Portland', status:'na'}],
                        2:[{org:'Prosper Portland', status:'na'}],
                        3:[{org:'Prosper Portland', status:'na'}],
                        4:[{org:'Prosper Portland', status:'na'}]
                    },
                    2025: {
                        1:[{org:'Prosper Portland', status:'na'}],
                        2:[{org:'Prosper Portland', status:'na'}],
                        3:[{org:'Prosper Portland', status:'na'}],
                        4:[{org:'Prosper Portland', status:'na'}]
                    },
                    2026: {
                        1:[{org:'Prosper Portland', status:'na'}],
                        2:[{org:'Prosper Portland', status:'na'}],
                        3:[{org:'Prosper Portland', status:'na'}],
                        4:[{org:'Prosper Portland', status:'na'}]
                    },
                },
                potentialPartners: ['Community Chamber Coalition of Oregon', 'PBDG', 'MESO', 'SBDC', 'economic development partners'],
                resources: ['Prosper Portland proposed general funds']
        },
        {
            goal: 2,
            objective: 2.1,
            action: 'E. Conduct an innovation opportunities assessment outlined in the Oregon Innovation Plan, January 2021, to determine the innovation needs of Oregon’s existing traded-sector firms, identify emerging market opportunities and help guide strategic investments in new Centers of Innovation Excellence.',
            outcome: 'Identify the greatest strategic innovation/ technology opportunities in Oregon to help guide its strategic investments in the Centers of Innovation Excellence.',
            metric: 'Representative crosssection of traded sector business leadership from across the region and number of potential Centers for Innovation Excellence candidates identified.',
            years: {
                    2022: {
                        1:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        2:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        3:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        4:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                    },
                    2023: {
                        1:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        2:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        3:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        4:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}]
                    },
                    2026: {
                        1:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        2:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        3:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}],
                        4:[{org:'Business Oregon', status:'na'},{org:'GPI', status:'na'}]
                    },
                },
                potentialPartners: ['Economic development partners', 'universities', 'business leadership'],
                resources: ['Leverage existing (Business Oregon is budgeting $80,000 to complete the assessment)']
        },
        {
            goal: 2,
            objective: 2.1,
            action: 'F. Advance Greater Portland Economic Recovery Plan actions for Priority 1 (Help Small Businesses Recover and Grow)',
            outcome: 'See Economic Recovery Plan',
            metric: 'See Economic Recovery Plan',
            years: {
                    2022: {
                        1:[{org:'GPI', status:'na'}],
                        2:[{org:'GPI', status:'na'}],
                        3:[{org:'GPI', status:'na'}],
                        4:[{org:'GPI', status:'inProgress'}],
                    },
                    2023: {
                        1:[{org:'GPI', status:'forthcoming'}],
                        2:[{org:'GPI', status:'forthcoming'}],
                        3:[{org:'GPI', status:'forthcoming'}],
                        4:[{org:'GPI', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'GPI', status:'na'}],
                        2:[{org:'GPI', status:'na'}],
                        3:[{org:'GPI', status:'na'}],
                        4:[{org:'GPI', status:'na'}]
                    },
                    2025: {
                        1:[{org:'GPI', status:'na'}],
                        2:[{org:'GPI', status:'na'}],
                        3:[{org:'GPI', status:'na'}],
                        4:[{org:'GPI', status:'na'}]
                    },
                    2026: {
                        1:[{org:'GPI', status:'na'}],
                        2:[{org:'GPI', status:'na'}],
                        3:[{org:'GPI', status:'na'}],
                        4:[{org:'GPI', status:'na'}]
                    },
                },
                potentialPartners: [],
                resources: []
        },
        {
            goal: 2,
            objective: 2.2,
            action: 'A. Support Centers of Innovation Excellence to facilitate the commercialization of research as a place to catalyze investments in new innovations.',
            outcome: 'Foster the commercialization of new technology emerging from R&D in higher ed institutions',
            metric: 'Number of federal grants leveraged',
            years: {
                    2022: {
                        1:[{org:'Business Oregon', status:'na'}],
                        2:[{org:'Business Oregon', status:'na'}],
                        3:[{org:'Business Oregon', status:'na'}],
                        4:[{org:'Business Oregon', status:'na'}],
                    },
                    2023: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                    2026: {
                        1:[{org:'Business Oregon', status:'forthcoming'}],
                        2:[{org:'Business Oregon', status:'forthcoming'}],
                        3:[{org:'Business Oregon', status:'forthcoming'}],
                        4:[{org:'Business Oregon', status:'forthcoming'}]
                    },
                },
                potentialPartners: ['GPI Higher-Ed Partners','Venture Catalyst'],
                resources: ['Leverage Existing; state investment in existing programs']
        },
        {
            goal: 2,
            objective: 2.2,
            action: 'B. Develop regional industry cluster work groups to advance cluster-specific strategies and projects for innovation, business recruitment, retention, and expansion.',
            outcome: 'Hear directly from private business regarding barriers and opportunities to inform clusterspecific strategies',
            metric: 'Number of new businesses or expansions within industry clusters',
            years: {
                    2022: {
                        1:[{org:'GPI', status:'na'}],
                        2:[{org:'GPI', status:'na'}],
                        3:[{org:'GPI', status:'na'}],
                        4:[{org:'GPI', status:'inProgress'}],
                    },
                    2023: {
                        1:[{org:'GPI', status:'forthcoming'}],
                        2:[{org:'GPI', status:'forthcoming'}],
                        3:[{org:'GPI', status:'forthcoming'}],
                        4:[{org:'GPI', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'GPI', status:'forthcoming'}],
                        2:[{org:'GPI', status:'forthcoming'}],
                        3:[{org:'GPI', status:'forthcoming'}],
                        4:[{org:'GPI', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'GPI', status:'forthcoming'}],
                        2:[{org:'GPI', status:'forthcoming'}],
                        3:[{org:'GPI', status:'forthcoming'}],
                        4:[{org:'GPI', status:'forthcoming'}]
                    },
                    2026: {
                        1:[{org:'GPI', status:'forthcoming'}],
                        2:[{org:'GPI', status:'forthcoming'}],
                        3:[{org:'GPI', status:'forthcoming'}],
                        4:[{org:'GPI', status:'forthcoming'}]
                    },
                },
                potentialPartners: ['EDPs', 'CREDC', 'Higher Ed partners', 'Prosper Portland cluster leads', 'Business Oregon', 'WA Dept. of Commerce', 'Venture Catalyst'],
                resources: ['Leverage Existing; EDA Technical Assistance']
        },
        {
            goal: 2,
            objective: 2.2,
            action: 'C. Support programs such as Growing Small Businesses Globally and state and regional investment in CDFIs to ensure BIPOC and marginalized entrepreneurs are able to scale their businesses for traded sector opportunities, after completing Action 2.1.A.',
            outcome: 'BIPOC entrepreneurs increase access to traded sector opportunities.',
            metric: 'Number of marginalized entrepreneurs engaged in scaling programs.',
            years: {
                2022: {
                    1:[{org:'GPI', status:'na'}],
                    2:[{org:'GPI', status:'na'}],
                    3:[{org:'GPI', status:'na'}],
                    4:[{org:'GPI', status:'na'}],
                },
                2023: {
                    1:[{org:'GPI', status:'forthcoming'}],
                    2:[{org:'GPI', status:'forthcoming'}],
                    3:[{org:'GPI', status:'forthcoming'}],
                    4:[{org:'GPI', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'GPI', status:'forthcoming'}],
                    2:[{org:'GPI', status:'forthcoming'}],
                    3:[{org:'GPI', status:'forthcoming'}],
                    4:[{org:'GPI', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'GPI', status:'forthcoming'}],
                    2:[{org:'GPI', status:'forthcoming'}],
                    3:[{org:'GPI', status:'forthcoming'}],
                    4:[{org:'GPI', status:'forthcoming'}]
                },
                2026: {
                    1:[{org:'GPI', status:'forthcoming'}],
                    2:[{org:'GPI', status:'forthcoming'}],
                    3:[{org:'GPI', status:'forthcoming'}],
                    4:[{org:'GPI', status:'forthcoming'}]
                },
            },
            potentialPartners: ['SBDC', 'Global Trade Center', 'TiE', 'Venture Catalyst'],
            resources: ['Banking & Philanthropic partners']
        },
        {
            goal: 2,
            objective: 2.3,
            action: 'A. Support/develop a steering committee of stakeholders and experts, including marketing specialists, to facilitate a regional branding process and implement recommendations. Focus on sustainability, innovation, global connections, B corp/corporate shared values.',
            outcome: 'Develop a message that resonates with existing and potential new businesses regarding the Portland region’s authentic assets',
            metric: 'Improvement to existing Travel Portland and ULI survey results. Improvement to Site Selection Magazine’s Top Metro’s list and YOY state Business Tax Climate Index. ',
            years: {
                    2022: {
                        1:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        2:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        3:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        4:[{org:'PBA', status:'complete'}, {org:'City of Portland', status:'missingData'}],
                    },
                    2023: {
                        1:[{org:'PBA', status:'forthcoming'}, {org:'City of Portland', status:'forthcoming'}],
                        2:[{org:'PBA', status:'forthcoming'}, {org:'City of Portland', status:'forthcoming'}],
                        3:[{org:'PBA', status:'forthcoming'}, {org:'City of Portland', status:'forthcoming'}],
                        4:[{org:'PBA', status:'forthcoming'}, {org:'City of Portland', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        2:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        3:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        4:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}]
                    },
                    2025: {
                        1:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        2:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        3:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        4:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}]
                    },
                    2026: {
                        1:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        2:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        3:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}],
                        4:[{org:'PBA', status:'na'}, {org:'City of Portland', status:'na'}]
                    },
                },
                potentialPartners: ['GPI', 'Travel Portland','Visit Vancouver', 'Port ofVancouver', 'CREDC', 'Cityof Vancouver', 'BusinessOregon'],
                resources: ['new program']
        },
        {
            goal: 2,
            objective: 2.3,
            action: 'B. Explore and promote regional strengths such as the region’s inclusive culture to international markets. Leverage resources of SelectUSA and overseas representatives of Business Oregon and the State of Washington to position the Greater Portland region to international investors.',
            outcome: 'Develop a message that resonates with foreign businesses regarding the Portland region’s authentic assets',
            metric: 'Foreign Direct Investment estimates for Washington and Oregon from Select USA',
            years: {
                    2022: {
                        1:[{org:'Greater Portland Global', status:'na'},{org:'GPI', status:'na'}],
                        2:[{org:'Greater Portland Global', status:'na'},{org:'GPI', status:'na'}],
                        3:[{org:'Greater Portland Global', status:'na'},{org:'GPI', status:'na'}],
                        4:[{org:'Greater Portland Global', status:'missingData'},{org:'GPI', status:'inProgress'}],
                    },
                    2023: {
                        1:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                    2026: {
                        1:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        2:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        3:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}],
                        4:[{org:'Greater Portland Global', status:'forthcoming'},{org:'GPI', status:'forthcoming'}]
                    },
                },
                potentialPartners: ['JASO', 'Business Oregon', 'WA Dept of Commerce'],
                    resources:['U.S. Department of Commerce']
        },
        {
            goal: 2,
            objective: 2.3,
            action: 'C. Secure support to ensure the NW Equity Summit is an annual event and can expand into an integrated regional program. Consider TRUE Northwest Arkansas as a model and visit with foundation and civic leaders to understand the program, level of investment and staff necessary to have a significant regional impact.',
            outcome: 'Enhance the Portland region culture of diversify to be more welcoming to BIPOC individuals particularly',
            metric: 'Increase annual participation in NW Equity Summit',
            years: {
                    2022: {
                        1:[{org:'Partners in Diversity', status:'na'}],
                        2:[{org:'Partners in Diversity', status:'na'}],
                        3:[{org:'Partners in Diversity', status:'na'}],
                        4:[{org:'Partners in Diversity', status:'na'}],
                    },
                    2023: {
                        1:[{org:'Partners in Diversity', status:'forthcoming'}],
                        2:[{org:'Partners in Diversity', status:'forthcoming'}],
                        3:[{org:'Partners in Diversity', status:'forthcoming'}],
                        4:[{org:'Partners in Diversity', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Partners in Diversity', status:'forthcoming'}],
                        2:[{org:'Partners in Diversity', status:'forthcoming'}],
                        3:[{org:'Partners in Diversity', status:'forthcoming'}],
                        4:[{org:'Partners in Diversity', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'Partners in Diversity', status:'na'}],
                        2:[{org:'Partners in Diversity', status:'na'}],
                        3:[{org:'Partners in Diversity', status:'na'}],
                        4:[{org:'Partners in Diversity', status:'na'}]
                    },
                    2026: {
                        1:[{org:'Partners in Diversity', status:'na'}],
                        2:[{org:'Partners in Diversity', status:'na'}],
                        3:[{org:'Partners in Diversity', status:'na'}],
                        4:[{org:'Partners in Diversity', status:'na'}]
                    },
                },
            potentialPartners: ['PGE', 'GPI', 'NW Natural'],
            resources: ['Leverage Existing Program']
        },
        {
            goal: 2,
            objective: 2.3,
            action: 'D. Develop a business success & job creation table to provide stabilization to Portland businesses and workers.',
            outcome: 'Convey to existing businesses in the City of Portland that they are needed assets for the city to prosper and they are supported',
            metric: 'Engage at least 100 businesses per year',
            years: {
                    2022: {
                        1:[{org:'City of Portland', status:'na'}],
                        2:[{org:'City of Portland', status:'na'}],
                        3:[{org:'City of Portland', status:'na'}],
                        4:[{org:'City of Portland', status:'missingData'}],
                    },
                    2023: {
                        1:[{org:'City of Portland', status:'forthcoming'}],
                        2:[{org:'City of Portland', status:'forthcoming'}],
                        3:[{org:'City of Portland', status:'forthcoming'}],
                        4:[{org:'City of Portland', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'City of Portland', status:'na'}],
                        2:[{org:'City of Portland', status:'na'}],
                        3:[{org:'City of Portland', status:'na'}],
                        4:[{org:'City of Portland', status:'na'}]
                    },
                    2025: {
                        1:[{org:'City of Portland', status:'na'}],
                        2:[{org:'City of Portland', status:'na'}],
                        3:[{org:'City of Portland', status:'na'}],
                        4:[{org:'City of Portland', status:'na'}]
                    },
                    2026: {
                        1:[{org:'City of Portland', status:'na'}],
                        2:[{org:'City of Portland', status:'na'}],
                        3:[{org:'City of Portland', status:'na'}],
                        4:[{org:'City of Portland', status:'na'}]
                    },
                },
                potentialPartners: ['GPI', 'City of Portland business leaders'],
                resources: ['New program']
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'A. Secure resources and facilitate construction of transportation projects listed in Appendix E. Engage private sector, economic and workforce development practitioners to confirm priority projects to include in the next Regional Transportation Plan (RTP) and advocate for existing projects that align with upcoming state and federal grant/funding opportunities.',
            outcome: 'Construct resilient infrastructure',
            metric: 'Number of resilient projects advanced and completed',
            years: {
                    2022: {
                        1:[{org:'Metro', status:'na'}, {org:'RTC', status:'na'}],
                        2:[{org:'Metro', status:'na'}, {org:'RTC', status:'na'}],
                        3:[{org:'Metro', status:'na'}, {org:'RTC', status:'na'}],
                        4:[{org:'Metro', status:'inProgress'}, {org:'RTC', status:'inProgress'}],
                    },
                    2023: {
                        1:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        2:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        3:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        4:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}]
                    },
                    2024: {
                        1:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        2:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        3:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        4:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        2:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        3:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        4:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}]
                    },
                    2026: {
                        1:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        2:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        3:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}],
                        4:[{org:'Metro', status:'forthcoming'}, {org:'RTC', status:'forthcoming'}]
                    },
                },
                potentialPartners: ['Regional cities and counties', 'GPI', 'Port of Portland', 'Port of Vancouver USA', 'AFL-CIO']
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'B. Support critical regional infrastructure projects, including Seismic Lifeline Route and Regional Emergency Transportation Route projects and other key projects such as the Interstate 5 Bridge Replacement Project, the Burnside Bridge Project, and Port of Portland’s Resilient Runway Project.',
            outcome: 'Construct resilient infrastructure',
            metric: 'Number of resilient projects advanced and completed',
            years: {
                    2022: {
                        1:[
                            {org:'Multnomah County', status:'na'},
                            {org:'Clark County', status:'na'},
                            {org:'RTC', status:'na'},
                            {org:'Metro', status:'na'},
                            {org:'Port of Portland', status:'na'},
                            {org:'Port of Vancouver', status:'na'}
                        ],
                        2:[
                            {org:'Multnomah County', status:'na'},
                            {org:'Clark County', status:'na'},
                            {org:'RTC', status:'na'},
                            {org:'Metro', status:'na'},
                            {org:'Port of Portland', status:'na'},
                            {org:'Port of Vancouver', status:'na'}
                        ],
                        3:[
                            {org:'Multnomah County', status:'na'},
                            {org:'Clark County', status:'na'},
                            {org:'RTC', status:'na'},
                            {org:'Metro', status:'na'},
                            {org:'Port of Portland', status:'na'},
                            {org:'Port of Vancouver', status:'na'}
                        ],
                        4:[
                            {org:'Multnomah County', status:'na'},
                            {org:'Clark County', status:'complete'},
                            {org:'RTC', status:'inProgress'},
                            {org:'Metro', status:'inProgress'},
                            {org:'Port of Portland', status:'na'},
                            {org:'Port of Vancouver', status:'inProgress'}
                        ],
                    },
                    2023: {
                        1:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        2:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        3:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        4:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                    },
                    2024: {
                        1:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        2:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        3:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        4:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                    },
                    2025: {
                        1:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        2:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        3:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        4:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                    },
                    2026: {
                        1:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        2:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        3:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                        4:[
                            {org:'Multnomah County', status:'forthcoming'},
                            {org:'Clark County', status:'forthcoming'},
                            {org:'RTC', status:'forthcoming'},
                            {org:'Metro', status:'forthcoming'},
                            {org:'Port of Portland', status:'forthcoming'},
                            {org:'Port of Vancouver', status:'forthcoming'}
                        ],
                    },
                },
                potentialPartners: ['Regional cities', 'AFL-CIO']
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'C. Support a process that collaboratively engages property owners to consider how unreinforced masonry (URM) buildings can be improved for resilience.',
            outcome: 'Increase supply of resilient buildings',
            metric: 'Number of URM projects completed',
            years: {
                    2022: {
                        1:[{org:'State engineering and building associations', status:'na'}],
                        2:[{org:'State engineering and building associations', status:'na'}],
                        3:[{org:'State engineering and building associations', status:'na'}],
                        4:[{org:'State engineering and building associations', status:'na'}],
                    },
                    2023: {
                        1:[{org:'State engineering and building associations', status:'na'}],
                        2:[{org:'State engineering and building associations', status:'na'}],
                        3:[{org:'State engineering and building associations', status:'na'}],
                        4:[{org:'State engineering and building associations', status:'na'}]
                    },
                    2024: {
                        1:[{org:'State engineering and building associations', status:'forthcoming'}],
                        2:[{org:'State engineering and building associations', status:'forthcoming'}],
                        3:[{org:'State engineering and building associations', status:'forthcoming'}],
                        4:[{org:'State engineering and building associations', status:'forthcoming'}]
                    },
                    2025: {
                        1:[{org:'State engineering and building associations', status:'forthcoming'}],
                        2:[{org:'State engineering and building associations', status:'forthcoming'}],
                        3:[{org:'State engineering and building associations', status:'forthcoming'}],
                        4:[{org:'State engineering and building associations', status:'forthcoming'}]
                    },
                    2026: {
                        1:[{org:'State engineering and building associations', status:'forthcoming'}],
                        2:[{org:'State engineering and building associations', status:'forthcoming'}],
                        3:[{org:'State engineering and building associations', status:'forthcoming'}],
                        4:[{org:'State engineering and building associations', status:'forthcoming'}]
                    },
                },
                potentialPartners: ["Regional cities and counties"]
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'D. Assess, develop and test emergency fuel management plans and emergency water provision plans for the bi-state region.',
            outcome: 'Determine measures to prioritize fuel and water, and to mitigate shortages in emergencies.',
            metric: 'Completed emergency fuel needs assessment and fuel management plans and conducted action report. Completed emergency water needs assessments and plans for emergency water provision in place for all counties.',
            years: {
                2022: {
                    1:[{org:'Regional Disaster Preparedness Organization', status:'na'}, ],
                    2:[{org:'Regional Disaster Preparedness Organization', status:'na'}, ],
                    3:[{org:'Regional Disaster Preparedness Organization', status:'na'}, ],
                    4:[{org:'Regional Disaster Preparedness Organization', status:'missingData'}, ],
                },
                2023: {
                    1:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    2:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    3:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    4:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                },
                2024: {
                    1:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    2:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    3:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    4:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                },
                2025: {
                    1:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    2:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    3:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    4:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                },
                2026: {
                    1:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    2:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    3:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                    4:[{org:'Regional Disaster Preparedness Organization', status:'forthcoming'}, ],
                },
            },
                potentialPartners: ['Oregon Department of Energy', 'Regional Water Providers Consortium'],
                resources: ["Homeland Security Urban Areas Security Initiative","U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'E. Invest in R&D for climate technology innovation by finding, funding, and supporting startups working to reverse the climate crisis and solve other environmental challenges.',
            outcome: 'Foster new technologies that will address impacts on climate change',
            metric: 'Inventory VertueLab startups addressing climate change and climate technology innovation.',
            years: {
                2022: {
                    1:[{org:'Vertue Lab', status:'na'}],
                    2:[{org:'Vertue Lab', status:'na'}],
                    3:[{org:'Vertue Lab', status:'na'}],
                    4:[{org:'Vertue Lab', status:'na'}],
                },
                2023: {
                    1:[{org:'Vertue Lab', status:'forthcoming'}],
                    2:[{org:'Vertue Lab', status:'forthcoming'}],
                    3:[{org:'Vertue Lab', status:'forthcoming'}],
                    4:[{org:'Vertue Lab', status:'forthcoming'}],
                },
                2024: {
                    1:[{org:'Vertue Lab', status:'forthcoming'}],
                    2:[{org:'Vertue Lab', status:'forthcoming'}],
                    3:[{org:'Vertue Lab', status:'forthcoming'}],
                    4:[{org:'Vertue Lab', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Vertue Lab', status:'forthcoming'}],
                    2:[{org:'Vertue Lab', status:'forthcoming'}],
                    3:[{org:'Vertue Lab', status:'forthcoming'}],
                    4:[{org:'Vertue Lab', status:'forthcoming'}],
                },
                2026: {
                    1:[{org:'Vertue Lab', status:'forthcoming'}],
                    2:[{org:'Vertue Lab', status:'forthcoming'}],
                    3:[{org:'Vertue Lab', status:'forthcoming'}],
                    4:[{org:'Vertue Lab', status:'forthcoming'}],
                },
            },
                potentialPartners: ['Regional cities and counties', 'Washington Procurement Technical Assistance Center', 'NW Natural', 'PGE'],
                resources: ["New Program"]
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'F. Explore the adoption of local policy across the region to use clean building materials in publicly funded infrastructure projects and leverage apprenticeships and workforce programs to ensure related skills development needs are advanced locally.',
            outcome: 'Encourage the use of clean manufactured building materials in publicly funded projects',
            metric: 'Survey local jurisdictions to see if a policy is adopted.',
            years: {
                2022: {
                    1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    4:[{org:'Port of Vancouver USA', status:'inProgress'}, {org:'Port of Portland', status:'na'}],
                },
                2023: {
                    1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    4:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                },
                2024: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2026: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
            },
                potentialPartners: ['Business Oregon', 'WA Dept of Commerce', 'Washington County'],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'G. Incorporate regional work completed by partners in executing on broadband infrastructure investments as prioritized in Governor Brown’s 10 Point Economic Recovery Plan and in the Washington State Department of Commerce’s Statewide Broadband Office.',
            outcome: 'Residents and businesses throughout the region have access to fast, reliable, and affordable broadband.',
            metric: 'Deliver broadband to all communities in the region.',
            years: {
                2022: {
                    1:[{org:'Business Oregon', status:'na'},{org:'Washington State Dept of Commerce', status:'na'}, ],
                    2:[{org:'Business Oregon', status:'na'},{org:'Washington State Dept of Commerce', status:'na'}, ],
                    3:[{org:'Business Oregon', status:'na'},{org:'Washington State Dept of Commerce', status:'na'}, ],
                    4:[{org:'Business Oregon', status:'na'},{org:'Washington State Dept of Commerce', status:'na'}, ],
                },
                2023: {
                    1:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    2:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    3:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    4:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                },
                2024: {
                    1:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    2:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    3:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    4:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                },
                2025: {
                    1:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    2:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    3:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    4:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                },
                2026: {
                    1:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    2:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    3:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                    4:[{org:'Business Oregon', status:'forthcoming'},{org:'Washington State Dept of Commerce', status:'forthcoming'}, ],
                },
            },
                potentialPartners: ['Cities and Ports', 'Oregon Broadband Advisory Council'],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'H. Support ability to compete in the river economy through multimodal connections to domestic and international markets via bi-state dredging sites.',
            outcome: 'Assess opportunities and gaps in river transportation network',
            metric: 'Completed report',
            years: {
                2022: {
                    1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    4:[{org:'Port of Vancouver USA', status:'inProgress'}, {org:'Port of Portland', status:'na'}],
                },
                2023: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2024: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    4:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                },
                2026: {
                    1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    4:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                },
            },
                potentialPartners: ['Lower Columbia River Channel Maintenance Plan', 'Friends of Frog Ferry', 'Columbia River Steamship Operators’ Association', 'Pacific Northwest Waterways Association'],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.1,
            action: 'I. Collaborate with community partners to ensure that public schools, hospitals, water systems, and other critical facilities have long duration backup power.',
            outcome: 'Construct resilient infrastructure',
            metric: 'Number of sites identified as suitable for clean backup generation.',
            years: {
                2022: {
                    1:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'}, {org:'GPI', status:'na'}],
                    2:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'},{org:'GPI', status:'na'} ],
                    3:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'},{org:'GPI', status:'na'} ],
                    4:[{org:'PGE', status:'na'},{org:'NW Natural', status:'overcome'},{org:'GPI', status:'na'} ],
                },
                2023: {
                    1:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'}, {org:'GPI', status:'na'}],
                    2:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'},{org:'GPI', status:'na'} ],
                    3:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'},{org:'GPI', status:'na'} ],
                    4:[{org:'PGE', status:'na'},{org:'NW Natural', status:'na'},{org:'GPI', status:'na'} ],
                },
                2024: {
                    1:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    2:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    3:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    4:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                },
                2025: {
                    1:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    2:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    3:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    4:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                },
                2026: {
                    1:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    2:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    3:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                    4:[{org:'PGE', status:'forthcoming'},{org:'NW Natural', status:'forthcoming'},{org:'GPI', status:'forthcoming'} ],
                },
            },
                potentialPartners: ['Regional cities and counties'],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'A. Retain and enhance incentives such as Opportunity Zones, R&D tax credits, Enterprise Zones (OR), Tax Increment Financing (TIF), State Small Business Credit Initiative (SSBCI), and SIP (Strategic Investment Program). Develop and advocate for new incentives to improve business competitiveness and support the workers they rely on.',
            outcome: 'Retain, enhance, and develop new incentives important to traded sector companies and small businesses',
            metric: 'Legislative summaries from OEDA/WEDA',
            years: {
                    2022: {
                        1:[{org:'OEDA', status:'na'}, {org:'WEDA', status:'na'}],
                        2:[{org:'OEDA', status:'na'}, {org:'WEDA', status:'na'}],
                        3:[{org:'OEDA', status:'na'}, {org:'WEDA', status:'na'}],
                        4:[{org:'OEDA', status:'missingData'}, {org:'WEDA', status:'missingData'}],
                    },
                    2023: {
                        1:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        2:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        3:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        4:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                    },
                    2024: {
                        1:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        2:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        3:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        4:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                    },
                    2025: {
                        1:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        2:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        3:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        4:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                    },
                    2026: {
                        1:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        2:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        3:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                        4:[{org:'OEDA', status:'forthcoming'}, {org:'WEDA', status:'forthcoming'}],
                    },
                },
                potentialPartners: ['Business Oregon', 'economic development partners', 'Venture Catalyst', 'AFL-CIO', 'BBPDX'],
                resources: []
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'B. Implement Oregon Brownfields Coalition priorities to incentivize Brownfield cleanup and redevelopment with short-term focus to enable landowners to pursue insurance assets associated with dissolved companies in support of Brownfield redevelopment opportunities (OR HB2377)',
            outcome: 'Increase number of buildable sites that are already served by infrastructure for business expansion and/or recruitment',
            metric: 'Number of new sites available and number of sites advancing tiers in readiness',
            years: {
                2022: {
                    1:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}],
                    2:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}],
                    3:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}],
                    4:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'inProgress'},],
                },
                2023: {
                    1:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                    2:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                    3:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                    4:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                },
                2024: {
                    1:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                    2:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                    3:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                    4:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, ],
                },
                2025: {
                    1:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                    2:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                    3:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                    4:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                },
                2026: {
                    1:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                    2:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                    3:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                    4:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, ],
                },
            },
                potentialPartners: ['OEDA', 'NW Natural',
                    'PGE', 'communities with Brownfields'],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'C. Implement recommendations in the Site Readiness Toolkit to move underused land into active economic uses. Includes expanding use of existing development tools, legislative tool modifications, and prospective application of new real estate and finance tools.',
            outcome: 'Invest in recommended actions to increase number of buildable sites for business expansion and/or recruitment',
            metric: 'Number of new sites available and number of sites advancing tiers in readiness',
            years: {
                2022: {
                    1:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    2:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    3:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    4:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'inProgress'}, {org:'GPI', status:'na'}],
                },
                2023: {
                    1:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    2:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    3:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    4:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                },
                2024: {
                    1:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    2:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    3:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    4:[{org:'Port of Portland', status:'forthcoming'},{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                    2:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                    3:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                    4:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                },
                2026: {
                    1:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                    2:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                    3:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                    4:[{org:'Port of Portland', status:'na'},{org:'Metro', status:'na'}, {org:'GPI', status:'na'},{org:'GPI', status:'na'}],
                },
            },
                potentialPartners: ['Port of Vancouver', 'WEDA/OEDA', 'NW Natural', 'PGE',
                'economic development partners', 'NAIOP'],
                resources: []
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'D. Evaluate key economic and employment trends affecting the region, including employment land supply, demand, and constraints in Metro’s Emerging Growth Trends work program which will serve to inform Metro’s 2024 Urban Growth Management decision.',
            outcome: 'Ensure growth management review cycle and land management policies reflect economic, employment, and industry needs and are structured to respond to market conditions. Maximize development capacity of existing lands and incorporate needed finance for construction and maintenance of supportive transportation and utility infrastructure.',
            metric: 'Clear and updated understanding of employment land needs.',
            years: {
                2022: {
                    1:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    4:[{org:'Metro', status:'inProgress'}, {org:'GPI', status:'inProgress'}],
                },
                2023: {
                    1:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    2:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    3:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    4:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}]
                },
                2024: {
                    1:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    2:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    3:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}],
                    4:[{org:'Metro', status:'forthcoming'}, {org:'GPI', status:'forthcoming'}]
                },
                2025: {
                    1:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    4:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}]
                },
                2026: {
                    1:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}],
                    4:[{org:'Metro', status:'na'}, {org:'GPI', status:'na'}]
                },
            },
                potentialPartners: ["Regional cities and counties", "Port of Portland","Port of Vancouver USA", "NAIOP", "Urban Land Institute", "NW Natural", "PGE"],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'E. Ensure regional support for Business Oregon, Oregon Department of Agriculture, and Washington Department of Commerce to position exporters to secure new markets overseas',
            outcome: 'Region continues to invest and actively engage in outreach with foreign markets',
            metric: 'Summary of engagements and number of contacts.',
            years: {
                2022: {
                    1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    4:[{org:'Port of Vancouver USA', status:'inProgress'}, {org:'Port of Portland', status:'na'}],
                },
                2023: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2024: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
                2026: {
                    1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                },
            },
            potentialPartners: ["Business Oregon", "WA Dept of Commerce", "US Commercial Service"],
            resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'F. Examine supply chain deficiencies and pursue nearshoring opportunities especially as it relates to the semiconductor industry that enhance the region’s ability to recruit and retain traded sector companies including an assessment of land development capacity.',
            outcome: 'Determine gaps in advanced manufacturing supply chain that need to be addressed.',
            metric: 'Completed report',
            years: {
                    2022: {
                        1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        4:[{org:'Port of Vancouver USA', status:'inProgress'}, {org:'Port of Portland', status:'missingData'}],
                    },
                    2023: {
                        1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                        2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                        3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                        4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    },
                    2024: {
                        1:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                        2:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                        3:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                        4:[{org:'Port of Vancouver USA', status:'forthcoming'}, {org:'Port of Portland', status:'forthcoming'}],
                    },
                    2025: {
                        1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        4:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    },
                    2026: {
                        1:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        2:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        3:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                        4:[{org:'Port of Vancouver USA', status:'na'}, {org:'Port of Portland', status:'na'}],
                    },
                },
                potentialPartners: ["Business Oregon", "WA Dept of Commerce", "Washington County"],
                resources: ["U.S. Economic Development Admin."]
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'G. Build and leverage affordable housing projects funded by the Metro bond measure in support of the regional economy and identify projects that support inclusive economic development objectives.',
            outcome: 'Bring more affordable housing units into the region',
            metric: 'Number of new affordable housing units',
            years: {
                2022: {
                    1:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    4:[{org:'Metro', status:'inProgress'}, {org:'city housing departments', status:'missingData'}],
                },
                2023: {
                    1:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    2:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    3:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    4:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                },
                2024: {
                    1:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    2:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    3:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    4:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    4:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                },
                2026: {
                    1:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    4:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                },
            },
                potentialPartners: ["NW Natural", "PGE", "AFL-CIO"],
                resources: []
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'H. Provide houselessness services funded by the Metro bond measure in support of the regional economy and identify projects and programs that support inclusive economic development objectives.',
            outcome: 'Support houseless individuals and provide them with indoor shelter and housing',
            metric: 'Decrease in unsheltered population as reported biannually by PSU',
            years: {
                2022: {
                    1:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    4:[{org:'Metro', status:'inProgress'}, {org:'city housing departments', status:'missingData'}],
                },
                2023: {
                    1:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    2:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    3:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    4:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                },
                2024: {
                    1:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    2:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    3:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                    4:[{org:'Metro', status:'forthcoming'}, {org:'city housing departments', status:'forthcoming'}],
                },
                2025: {
                    1:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    4:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                },
                2026: {
                    1:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    2:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    3:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                    4:[{org:'Metro', status:'na'}, {org:'city housing departments', status:'na'}],
                },
            },
                potentialPartners: [],
                resources: []
        },
        {
            goal: 3,
            objective: 3.2,
            action: 'I. Support strategic remote work and placemaking investments region-wide to support job and housing growth and reduce climate impacts.',
            outcome: 'Foster environment for remote workers to live and work in suburban or rural communities to reduce commuting and climate impacts.',
            metric: 'Number of workers moving to and living in suburbs/Number of remote workers in suburbs',
            years: {
                    2022: {
                        1:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        2:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        3:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        4:[{org:'Metro', status:'inProgress'}, {org:'Economic Development Partners', status:'missingData'}],
                    },
                    2023: {
                        1:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                        2:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                        3:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                        4:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                    },
                    2024: {
                        1:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                        2:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                        3:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                        4:[{org:'Metro', status:'forthcoming'}, {org:'Economic Development Partners', status:'forthcoming'}],
                    },
                    2025: {
                        1:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        2:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        3:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        4:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                    },
                    2026: {
                        1:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        2:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        3:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                        4:[{org:'Metro', status:'na'}, {org:'Economic Development Partners', status:'na'}],
                    },
                },
                potentialPartners: [],
                resources: ["U.S. Economic Development Admin."]
        },
]


export const stories = [
    {
        goal: 2,
        objective: 2.3,
        action: 'A',
        headline: "\"Oregon: The State of Sport\" reveals that Oregon's sport ecosystem supports $29B per year in economic output.",
        content: "The Portland Business Alliance convened a regional and statewide coalition of public and private enterprises to retain HR&A advisors, an economic development consulting firm, to conduct the most robust report to date of Orgeon's Athletic, Outdoor, Team, and Recreation Ecosystem -- defined as including sports and apparel companies as well as sporting related events, recreation, and tourism activities. While the ecosystem spans a state that has a large number of communities with a strong foothold in this space, this initial report defines the \"study area\" as Greater Portland, Bend, and Eugene, with a deeper dive into the dynamics of Greater Portland.",
        link:'https://oregonstateofsport.com/',
        image: sportImg,
        caption: "Direct economic activity in the ecosystem generates $1B in annual tax revenues to the state of Oregon"
    },
    {goal: 1,
    objective: 1.1,
    action: 'A',
    headline: "SINE is a membership network of Oregon professionals committed to leading in their vocation, building wealth, and changing the world for good.",
    content: 'In March 2021, The Contingent launched Survival Is Not Enough (SINE), an initiative that supports Oregon and SW Washington BIPOC college graduates by building a network of professionals, who are committed to leading in their vocation, building wealth, and changing our community and the world for good. SINE invests in the social mobility of young leaders of color through financial education, professional development, civic and community engagement, and wealth building.',
    link: 'https://sineup.org/',
    image: sineImg,
    caption: '"In Oregon, depending on where you live or the color of your skin, flourishing is not guaranteed: survival is not enough! We have created a model to ensure that the leaders in our communities will reflect the people who live there."'
    },
    {
        goal: 1,
        objective: 1.1,
        action: 'A',
        headline: 'OCCA helps childcare businesses increase their revenue, solve challenging business problems, and reduce their stressful workload through a “shared services alliance” ',
        content: 'The Oregon Childcare Alliance (OCCA) takes an approach to child care management and leadership designed to help small child care businesses achieve financial sustainability and enable strong childhood outcomes and family supports. OCCA identifies common needs and creates a structure to share resources (recordkeeping software, tax prep, website and marketing, business coaching) among a network child care programs.',
        link: "https://nhpdx.org/oregon-childcare-alliance/",
        image: occa,
        caption: "Child care is an essential service, and COVID highlighted this and the fact that child care programs need more support to survive and thrive.",

    }
]

export const orgContacts = [
    {org:'GPI', emailOne:'monique.claiborne@greaterportlandinc.com', website:'https://www.greaterportlandinc.com/'},
    {org:'Columbia-Willamete Workforce Collaborative', emailOne: 'mhalliday@workforcesw.org', website:'https://www.worksystems.org/our-approach/business-investments/regional-workforce-collaborative'},
    {org:'Metro', emailOne:'margi.bradway@oregonmetro.gov', website:'https://www.oregonmetro.gov/'},
    {org:'Community Chamber Coallition of Oregon', emailOne: 'marcus@coalitioncommunitiescolor.org', website:'https://www.coalitioncommunitiescolor.org/'},
    {org:'Clark County', emailOne:'Lindsey.Hueer@clark.wa.gov', website:'https://clark.wa.gov/councilors/county-council-approves-implementation-clean-energy-finance-program'},
    {org:'Port of Vancouver USA', emailOne:'mbomar@portvanusa.com', website:'https://www.portvanusa.com/contacts/mike-bomar/'},
    {org:'WSU Vancouver', emailOne:'narek.daniyelyan@wsu.edu', website: 'https://www.vancouver.wsu.edu/'},
    {org:'RTC', emailOne:'matt.ransom@rtc.wa.gov', website: 'https://www.rtc.wa.gov/agency/staff/'},
    {org:'PBA', emailOne:'Ahoan@portlandalliance.com', website:'https://portlandalliance.com/about/staff.html'},
    {org:'NW Natural', emailOne:'alan.garcia@nwnatural.com', website:'https://www.nwnatural.com/'},
]