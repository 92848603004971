import React, {useState} from 'react';
import { colors } from '../../utils/style/colors';
import { goals, objectives } from './data';
import Button from '@mui/material/Button';
import SunburstChart from './SunburstChart';
import RoseProgressChart from './RoseProgressChart';
import StoryHighlight from './StoryHighlight';
import GoalIntro from './GoalIntro';
import CEDSIntro from './CEDSIntro';
import GoalOneCharts from './GoalOneCharts';
import GoalThreeCharts from './GoalThreeCharts';
import check from '../../images/icons-done.svg';
import measure from '../../images/icon-chart-bar.png';
import GoalTwoCharts from './GoalTwoCharts';
import Tooltip from '@mui/material/Tooltip';

const Story = ({stories, actions, goal, objective, setObjective}) => { 

 const [highlightedAction, setHighlightedAction] = useState('');
 const [selectedAction, setSelectedAction] = useState('');

 const selectedObjective = objectives.find(o => `${o.objective}` === `${objective}`)
 const objectiveList = objectives.filter(objective => `${objective.goal}` === `${goal}`);
 const actionList = actions.filter(action => `${action.objective}` === `${objective}`);
 const filteredActionData = selectedAction ? actionList.filter(a => a.action.startsWith(selectedAction)) : actionList;
 const storiesList = stories.filter(s => `${s.goal}` === `${goal}`).map(s => {
    return <StoryHighlight story={s}/>
 });
 

 const currentProgress = (action) => {
    const progressScale = {
        complete: 6,
        inProgress: 5,  
        overcome: 4,
        notStarted: 3, 
        missingData: 2,
        forthcoming: 1,
        na: 0
    }
    const scores = Object.keys(action.years).map(y => {
        return Object.keys(action.years[y]).map(q => {
            const quarter = action.years[y][q];
            const quarterScores = quarter.map(org => progressScale[org.status])
            return quarterScores;
        });
    })
    const flatScores = scores.flat().flat()
    const max = Math.max(...flatScores);
    return max;
 }

 const progressSummary = actionList.map(a => currentProgress(a));

 const progressColor = [
    colors.backgroundWhite,
    colors.forthcomingCharcoal,
    '#fade91',
    colors.notStartedYellow,
    colors.notStartedYellow,
    colors.progressTeal,
    colors.completedGreen
]

 const actionItems = filteredActionData.map((a, aIndex) => {
        const highlighted = highlightedAction ? a.action.startsWith(highlightedAction) : false;
        const progress = currentProgress(a);
        const hasBorder = progress < 2 ;
        let border = `2px solid ${progressColor[progress]}`;
 
            return (
                <section key={aIndex} style={{display: 'flex', alignItems: 'start', justifyContent: 'flex-start', fontSize: '15px', margin: '0px', backgroundColor: highlighted ? colors.tableRowTeal : colors.backgroundWhite, border: highlighted ? `solid 3px ${colors.selectedDeepBlue}` : 'none'}}>
                    <p style={{marginRight: '10px', marginLeft: '0px', marginRight: '5px', textAlign: 'left'}}>{a.action}</p>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', padding: '0px'}}>
                        <section style={{display: 'flex', width: '300px', alignItems: 'center', marginBottom: '-10px', padding: '0px', marginTop: '0px'}}>
                        <Tooltip placement="top-start" title={`priority outcome`} arrow>
                            <div style={{backgroundColor: progressColor[progress], border: border, width: '25px', height: '25px', borderRadius: '50%'}}><img style={{height: '20px'}} src={check}/></div>
                        </Tooltip>
                            <p style={{width: '250px', textAlign: 'left', marginLeft: '5px'}}>{a.outcome}</p>
                        </section>
                        <section style={{display: 'flex', width: '300px', alignItems: 'center'}}>
                            <Tooltip placement="top-start" title={`recommended metric`} arrow>
                                <div style={{backgroundColor: progressColor[progress], border: border, width: '25px', height: '25px', borderRadius: '50%'}}><img style={{height: '20px'}} src={measure}/></div>
                            </Tooltip>
                            <p style={{width: '250px', textAlign: 'left', marginLeft: '5px'}}>{a.metric}</p>
                        </section>
                    </div>
                </section>
            )
    })

    const objectiveButtons = objectiveList.map((o, oIndex) => {
        return <Button key={oIndex} style={{width: '200px', margin: '10px', backgroundColor: o.objective === objective ? colors.selectedDeepBlue : '#cfcfcf'}} onClick={()=>{setObjective(o.objective)}} variant="contained">{`Objective ${o.objective}`}</Button>
    })

  return (
    <div className="story" style={{lineHeight: '190%', width: '90vw', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '5vw', paddingRight: '5vw', paddingBottom: '10vh'}}>
      <CEDSIntro />
      <GoalIntro goal={goal}/>
      <div style={{display: 'flex', justifyContent: "flex-start", width: '100%', height: '70px', marginTop: '30px', backgroundColor: colors.backgroundWhite}}>
        {objectiveButtons}
      </div>
      <div className='column-mobile' style={{display:'flex', justifyContent: 'space-evenly', width: '100%'}}>
        <div className='full-width-mobile' style={{width: '50%'}}>
            <p style={{fontSize: '24px', fontWeight: 'bold', textAlign: 'left', marginBottom: '20px', }}>{`OBJECTIVE ${selectedObjective.objective} ${selectedObjective.description}`}</p>
            <div style={{lineHeight: '120%'}}>
                {actionItems}
            </div>
        </div>
        <div className='full-width-mobile' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%'}}>
            <div className="rose-container" style={{marginTop: '80px', zIndex: '0'}}>
                <RoseProgressChart progressSummary={progressSummary}/>
             </div>
             <div className="sunburst-container" style={{marginTop: '-50px', zIndex: '0'}}>
                <SunburstChart objective={objective} setHighlightedAction={setHighlightedAction} setSelectedAction={setSelectedAction} actions={actionList}/>
            </div>
        </div>
      </div>
      {storiesList}
      {goal === 1 && <GoalOneCharts />}
      {goal === 2 && <GoalTwoCharts />}
      {goal === 3 && <GoalThreeCharts />}
    </div>
  );
}

export default Story;